import { Avatar, Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 3),
}));

type Props = {
  img?: string;
  title: string;
  subtitle?: string;
  groupName?: string;
};

export default function NavbarCard({ img, title, subtitle, groupName }: Props) {
  return (
    <Link underline="none" color="inherit">
      <RootStyle>
        {img && <Avatar src={img} alt={title} sx={{ mr: 2 }} />}
        <Box
          sx={{
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            overflow: 'hidden',
          }}
        >
          <Typography variant="subtitle2" noWrap>
            {title}
          </Typography>
          {groupName && (
            <Typography
              variant="subtitle2"
              noWrap
              sx={{ color: 'text.secondary', textTransform: 'capitalize' }}
            >
              {groupName}
            </Typography>
          )}
          {subtitle && (
            <Typography
              variant="body2"
              noWrap
              sx={{ color: 'text.secondary', textTransform: 'capitalize' }}
            >
              {subtitle}
            </Typography>
          )}
        </Box>
      </RootStyle>
    </Link>
  );
}
