import { CheckboxProps, Checkbox as MuiCheckbox, Stack, Typography } from '@mui/material';
import { ReactNode, forwardRef } from 'react';

type Props = {
  label?: string | ReactNode;
} & CheckboxProps;

export const Checkbox = forwardRef<HTMLButtonElement, Props>(({ label, ...other }, ref) => (
  <Stack display="flex" direction="row" alignItems="center" gap={1}>
    <MuiCheckbox {...other} sx={{ height: 'fit-content', ...other.sx }} ref={ref} />
    {typeof label === 'string' ? (
      <Typography variant="body2" display="inline-block" sx={{ cursor: 'pointer' }}>
        {label}
      </Typography>
    ) : (
      label
    )}
  </Stack>
));
