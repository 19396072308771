import { CheckboxProps } from '@mui/material';
import { Checkbox } from 'components/Checkbox';
import { FC, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  label: string | ReactNode;
} & CheckboxProps;

export const CheckboxWithLabel: FC<Props> = ({ label, ...other }) => {
  const { control } = useFormContext();

  const { name } = { ...other };

  return (
    <Controller
      name={name ?? ''}
      control={control}
      render={({ field }) => (
        <label style={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
          <Checkbox {...field} {...other} label={label} />
        </label>
      )}
    />
  );
};
