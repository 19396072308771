import CloseIcon from '@mui/icons-material/Close';
import {
  AutocompleteRenderInputParams,
  AutocompleteValue,
  Box,
  Checkbox,
  Chip,
  InputAdornment,
  Autocomplete as MUIAutocomplete,
  AutocompleteProps as MUIAutocompleteProps,
  Popper,
  PopperProps,
  TextField,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { includes } from 'lodash';
import { ReactNode, Ref, useCallback } from 'react';
import { forwardRefForGeneric } from 'utils/baseUtils';
import Iconify from './Iconify';
import SearchNotFound from './SearchNotFound';

const AutocompleteChip = styled(Chip)({
  display: 'flex',
  fontSize: 'large',
  justifyContent: 'center',
});

const PopperStyle = styled((props: PopperProps) => <Popper placement="bottom-start" {...props} />)({
  width: '280px !important',
});

const ClearContainer = styled(Box)({
  position: 'absolute',
  right: 10,
  top: '54%',
  transform: 'translateY(-46%)',
  ':hover': { cursor: 'pointer' },
});

export type AutocompleteInputParamsProps = {
  required?: boolean;
  placeholder?: string;
  helperText?: string;
  error?: boolean;
  label?: string;
};

export type AutocompleteProps<
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Omit<
  MUIAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>,
  'renderInput' | 'onChange' | 'noOptionsText' | 'onInputChange' | 'renderOption'
> & {
  inputParams: AutocompleteInputParamsProps;
  searchedText?: string;
  isSearchFilteringHandledExternally?: boolean; // true if the caller of this component filters the options itself
  renderOption?: (value: Value) => ReactNode;
  onInputChange?: (value: string) => void;
  onChange: (value: AutocompleteValue<Value, Multiple, DisableClearable, FreeSolo>) => void;
  shouldRenderTags?: boolean;
  optionNotFoundComponent?: ReactNode;
};

export const Autocomplete = forwardRefForGeneric(
  <
    Value,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    FreeSolo extends boolean | undefined = false,
  >(
    {
      inputParams,
      onChange,
      shouldRenderTags,
      searchedText,
      renderOption,
      onInputChange,
      isSearchFilteringHandledExternally,
      filterOptions,
      sx,
      optionNotFoundComponent,
      ...props
    }: AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>,
    ref?: Ref<HTMLDivElement>
  ) => {
    type ActualComponentProps = MUIAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>;

    const showAdornment = !shouldRenderTags;
    const { required, placeholder, helperText, label, error } = inputParams;
    const { multiple, getOptionLabel } = props;
    const theme = useTheme();

    const actualRenderInput: ActualComponentProps['renderInput'] = useCallback(
      (params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          required={required}
          placeholder={placeholder}
          helperText={helperText}
          label={label}
          error={error}
          InputProps={{
            ...params.InputProps,
            style: { paddingRight: '35px' },
            ...(showAdornment && {
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }}
                  />
                </InputAdornment>
              ),
              endAdornment: !props.disableClearable && !props.disabled && (
                <ClearContainer
                  onClick={() => {
                    onChange((props.multiple ? [] : null) as any);
                  }}
                >
                  <CloseIcon sx={{ color: theme.palette.grey[500] }} />
                </ClearContainer>
              ),
            }),
          }}
        />
      ),
      [
        helperText,
        placeholder,
        required,
        showAdornment,
        label,
        error,
        onChange,
        props.disableClearable,
        props.disabled,
        props.multiple,
        theme.palette.grey,
      ]
    );

    const actualOnInputChange: ActualComponentProps['onInputChange'] =
      onInputChange &&
      ((e, newValue) => {
        // Prevent onInputChange call when user select an option
        if (e && (e.type === 'change' || includes(String(e.target), 'SVG')))
          onInputChange(newValue);
      });

    const actualOnChange: ActualComponentProps['onChange'] = (_, value) => onChange(value);

    const actualRenderTags: ActualComponentProps['renderTags'] = shouldRenderTags
      ? (tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <AutocompleteChip
              {...getTagProps({ index })}
              label={getOptionLabel ? getOptionLabel(option) : ''}
              color="primary"
            />
          ));
        }
      : () => undefined;

    const actualRenderOption: ActualComponentProps['renderOption'] = (prps, option, state) => (
      <li {...prps} key={state.index}>
        {multiple && <Checkbox checked={state.selected} size="small" />}
        {renderOption ? renderOption(option) : getOptionLabel ? getOptionLabel(option) : ''}
      </li>
    );

    const actualFilterOptions: ActualComponentProps['filterOptions'] =
      isSearchFilteringHandledExternally ? (options, _) => options : filterOptions;

    return (
      <MUIAutocomplete
        // defaults props
        disableCloseOnSelect={multiple}
        popupIcon={null}
        PopperComponent={PopperStyle}
        noOptionsText={optionNotFoundComponent || <SearchNotFound searchQuery={searchedText} />}
        size="medium"
        fullWidth
        autoHighlight
        // wrapped props
        renderInput={actualRenderInput}
        onInputChange={actualOnInputChange}
        onChange={actualOnChange}
        renderTags={actualRenderTags}
        renderOption={actualRenderOption}
        filterOptions={actualFilterOptions}
        sx={{
          '& button.MuiAutocomplete-clearIndicator': {
            visibility: 'visible',
          },
          ...sx,
        }}
        clearIcon={null}
        // others
        {...props}
        ref={ref}
      />
    );
  }
);
