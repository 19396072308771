export const navigateToNewTab = (url: string) => window.open(url, '_blank')?.focus();

export const navigateToIdentityProvider = (
  identity_provider_url: string,
  redirectionUrl: string
) => {
  return window.location.replace(
    `${identity_provider_url}&RelayState=${encodeURIComponent(redirectionUrl || '')}`
  );
};
