import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { faksApiInstance, queryClient } from 'api';
import { ResellerWording } from 'types/models';
import { RootState } from '../store';

export type AuthState = {
  phoneToken: string | null;
  phoneNumber: string | null;
  userToken: string | null;
  trueUserId: number | null;
  currentUserId: number | null;
  currentRoleId: number | null;
  onboardingResellerType: ResellerWording | null;
};

type SignOutResponse = {
  success: boolean;
  single_log_out_url?: string;
};

const initialState: AuthState = {
  phoneToken: null,
  phoneNumber: null,
  userToken: null,
  trueUserId: null,
  currentUserId: null,
  currentRoleId: null,
  onboardingResellerType: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setPhoneToken(state, action: PayloadAction<string>) {
      state.phoneToken = action.payload;
    },
    setPhoneNumber(state, action: PayloadAction<string>) {
      state.phoneNumber = action.payload;
    },
    setUserToken(state, action: PayloadAction<string>) {
      state.userToken = action.payload;
    },
    setTrueUserId(state, action: PayloadAction<number>) {
      state.trueUserId = action.payload;
    },
    setCurrentUserId(state, action: PayloadAction<number>) {
      state.currentUserId = action.payload;
    },
    setCurrentRoleId(state, action: PayloadAction<number | null>) {
      const newRoleId = action.payload;

      if (newRoleId !== state.currentRoleId) {
        state.currentRoleId = newRoleId;
        queryClient.clear();
      }
    },
    setOnboardingResellerType(state, action: PayloadAction<ResellerWording | null>) {
      state.onboardingResellerType = action.payload;
    },
    startImpersonatingUser(state, action: PayloadAction<{ user_id: number; id: number }>) {
      const roleToImpersonate = action.payload;
      state.currentUserId = roleToImpersonate.user_id;
      state.currentRoleId = roleToImpersonate.id;
      queryClient.clear();
    },
    clearImpersonatedUser(state) {
      state.currentUserId = state.trueUserId;
      state.currentRoleId = null;
      queryClient.clear();
    },
    signOut(state, action: PayloadAction<{ userInitiated?: boolean }>) {
      if (state.userToken && action.payload?.userInitiated) {
        faksApiInstance
          .post<SignOutResponse>('sign_out', {}, { headers: { Authorization: state.userToken } })
          .then((res) => {
            if (res.single_log_out_url) {
              window.location.replace(res.single_log_out_url);
            }
          });
      }
      queryClient.clear();
      Sentry.setUser(null);
      Sentry.setTags({ true_user_id: null });
      window.analytics.reset();
      window.Intercom?.('shutdown');
      window.Intercom?.('boot', { app_id: process.env.REACT_APP_INTERCOM_APP_ID });
      return { ...initialState, onboardingResellerType: state.onboardingResellerType };
    },
  },
});

export const {
  setPhoneToken,
  setPhoneNumber,
  setUserToken,
  setTrueUserId,
  setCurrentUserId,
  setCurrentRoleId,
  setOnboardingResellerType,
  startImpersonatingUser,
  clearImpersonatedUser,
  signOut,
} = slice.actions;

export const selectPhoneToken = (state: RootState) => state.auth.phoneToken;
export const selectPhoneNumber = (state: RootState) => state.auth.phoneNumber;
export const selectUserToken = (state: RootState) => state.auth.userToken;
export const selectOnboardingResellerType = (state: RootState) => state.auth.onboardingResellerType;

export const selectCurrentUserId = (state: RootState) => state.auth.currentUserId;
export const selectTrueUserId = (state: RootState) => state.auth.trueUserId;
export const selectCurrentRoleId = (state: RootState) => state.auth.currentRoleId;

export default slice.reducer;
