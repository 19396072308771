import { useCurrentLab } from '../useCurrentLab';

export const useCurrentLabOrThrow = () => {
  const currentLab = useCurrentLab();
  if (!currentLab) {
    throw new Error('Current lab is not defined');
  }

  return currentLab;
};
