import { useTheme as theme } from '@mui/material/styles';
import { FC } from 'react';
import { SvgIconProps } from 'types/icons';

const Cross: FC<SvgIconProps> = ({ color = theme().palette.grey[500], size = 24 }) => (
  <svg width={size} height={size} fill="none" viewBox="0 0 22 22">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.223 18.777A11 11 0 1 1 18.507 2.953 11 11 0 0 1 3.223 18.777ZM12.54 11l3.113-3.113-1.551-1.55L11 9.448 7.887 6.336 6.336 7.887 9.449 11l-3.113 3.113 1.551 1.551L11 12.551l3.113 3.113 1.551-1.55L12.551 11h-.011Z"
      fill={color}
    />
  </svg>
);

export default Cross;
