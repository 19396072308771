import { useSelector } from 'react-redux';
import { selectCurrentRoleId } from 'redux/slices/auth';
import { useCurrentUser } from './useCurrentUser';

export const useCurrentRole = () => {
  const roleId = useSelector(selectCurrentRoleId);
  const currentRoles = useCurrentUser()?.roles;

  return currentRoles?.find((role) => role.id === roleId) || null;
};
