import { Link } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 600,
  '&:hover': {
    cursor: 'pointer',
    color: theme.palette.primary.dark,
    textDecoration: 'none',
  },
}));
