import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded';
import { Box, Stack } from '@mui/system';
import { Banner } from 'components/banner/banner';
import { Trans } from 'react-i18next';
import { Panelist } from 'types/models/pharmacy_lgo_settings';
import { PanelistConnectionSteps } from '../PanelistConnectionSteps';

type Props = {
  panelist: Panelist | null | undefined;
};

export const ConnectionEstablishingBanner = ({ panelist }: Props) => (
  <Banner
    icon={<WatchLaterRoundedIcon fontSize="medium" sx={{ marginRight: 1 }} />}
    title={
      <Stack gap={2}>
        <Box>
          <Trans
            i18nKey={'pharmacyLgoConnection.connectionEstablishing'}
            components={[<strong />]}
          />
        </Box>
        <Box>{panelist && <PanelistConnectionSteps panelist={panelist} />}</Box>
      </Stack>
    }
    color="warning"
    sx={{ mt: 2 }}
  />
);
