import { AxiosError } from 'axios';
import { UseMutationOptions, UseMutationResult, useMutation } from 'react-query';
import { SendPharmaVerifyEmailParams, SendPharmaVerifyEmailResponse } from 'types/api/auth';
import { ApiError, faksApiInstance as api } from './api';

export const useSendPharmacyVerificationEmail = (
  options?: UseMutationOptions<
    SendPharmaVerifyEmailResponse,
    AxiosError<ApiError>,
    SendPharmaVerifyEmailParams
  >
): UseMutationResult<
  SendPharmaVerifyEmailResponse,
  AxiosError<ApiError>,
  SendPharmaVerifyEmailParams
> =>
  useMutation<SendPharmaVerifyEmailResponse, AxiosError<ApiError>, SendPharmaVerifyEmailParams>(
    (params) =>
      api.post<SendPharmaVerifyEmailResponse>(
        `pharmacies/${params.pharmacyId}/send_verify_pharmacy_email`
      ),
    options
  );
