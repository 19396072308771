import ErrorIcon from '@mui/icons-material/Error';
import { Box, Typography } from '@mui/material';
import { queryClient, useCreateMutation, useIndexQuery } from 'api';
import { CenteredSpinner } from 'components/CenteredSpinner';
import PageContainer from 'components/PageContainer';
import { Banner } from 'components/banner/banner';
import { Info } from 'components/icons';
import { useCurrentRole, useCurrentRoleOrThrow } from 'hooks/slices';
import { useOnErrorNavigation } from 'hooks/useOnErrorNavigation';
import { useTranslation } from 'locales/i18n';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import { SetPanelistConnectionParams } from 'types/api/pharmacies/panelist_connection';
import { ConnectPanelistCard } from './ConnectPanelistCard';
import { PanelistConnectedCard } from './PanelistConnectedCard';
import { PanelistConnectionStatusIcon } from './PanelistStatusComponents';

export const useGetPanelistConnectionStatus = () => {
  const currentRole = useCurrentRole();

  const { data, isLoading } = useIndexQuery(
    'pharmacies/panelist_connection/status',
    {},
    { enabled: currentRole?.resource_type === 'Pharmacy' }
  );

  return {
    panelistConnection: data,
    isPharmacyAllowedToEstablishConnection: data?.status !== 'not_allowed',
    panelist: data?.pharmacy_panelist?.panelist,
    isLoading: isLoading,
  };
};

export const PharmacyPanelistConnectionPage = () => {
  const { t } = useTranslation();
  const { handleErrorNavigation } = useOnErrorNavigation();

  const [isLoading, setIsLoading] = useState(false);
  const currentRole = useCurrentRoleOrThrow();

  const setPanelistConnectionMutation = useCreateMutation('pharmacies/panelist_connection/_set');
  const resetPanelistConnection = useCreateMutation('pharmacies/panelist_connection/_reset');

  const { panelistConnection, isPharmacyAllowedToEstablishConnection } =
    useGetPanelistConnectionStatus();

  const onSetPanelistConnectionHandler = (
    panelistConnectionParams: SetPanelistConnectionParams
  ) => {
    setIsLoading(true);

    setPanelistConnectionMutation
      .mutateAsync(panelistConnectionParams)
      .then(() => {
        toast.warning(t('pharmacyLgoConnection.connectionSuccess'));
        queryClient.invalidateQueries('pharmacies/panelist_connection/status');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onResetPanelistConnectionHandler = () => {
    setIsLoading(true);

    resetPanelistConnection
      .mutateAsync({} as never)
      .then(() => {
        toast.success(t('pharmacyLgoConnection.logoutSuccess'));
        queryClient.invalidateQueries('pharmacies/panelist_connection/status');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const isUserNotPharmacyOwner = currentRole.kind !== 'pharmacy_owner';

  useEffect(() => {
    if (!panelistConnection && !isPharmacyAllowedToEstablishConnection) {
      handleErrorNavigation(403);
    }
  }, [panelistConnection, isPharmacyAllowedToEstablishConnection, handleErrorNavigation]);

  if (!panelistConnection) return <CenteredSpinner />;

  return (
    <PageContainer
      pageTitle={t('shared.panelistConnection')}
      pageTitleBadge={
        <PanelistConnectionStatusIcon status={panelistConnection.status} fontSize="medium" />
      }
    >
      {!isPharmacyAllowedToEstablishConnection ? (
        <Banner
          icon={<ErrorIcon fontSize="medium" sx={{ marginRight: 1 }} />}
          title={t('pharmacyLgoConnection.notAllowed')}
          color="error"
        />
      ) : (
        <>
          {isUserNotPharmacyOwner && (
            <Banner
              icon={<Info size={30} />}
              title={
                <Box ml={1}>
                  <Typography>
                    <Trans
                      i18nKey="pharmacyLgoConnection.askOwnerToCreateOne"
                      components={{ bold: <strong /> }}
                    />
                  </Typography>
                </Box>
              }
              color="warning"
              sx={{ mb: 2 }}
            />
          )}
          {panelistConnection.pharmacy_panelist?.panelist ? (
            <PanelistConnectedCard
              connectedLgoSettings={panelistConnection.pharmacy_panelist}
              panelistConnection={panelistConnection}
              handleOnReset={onResetPanelistConnectionHandler}
              disableDisconnectButton={isUserNotPharmacyOwner}
              isLoading={isLoading}
            />
          ) : (
            <ConnectPanelistCard
              handleOnSet={onSetPanelistConnectionHandler}
              disableConnectButton={isUserNotPharmacyOwner}
              isLoading={isLoading}
            />
          )}
        </>
      )}
    </PageContainer>
  );
};
export default PharmacyPanelistConnectionPage;
