import { useEffect } from 'react';
import {
  getOrganizationRoleResourceTypeKey,
  trackGroup,
  trackIdentify,
} from '../utils/trackingUtils';
import { useCurrentRole, useCurrentUser } from './slices';

// This hooks is used to (re)initialize the user tracking.
// thanks to its two dependencies, it is expected to run in the following cases:
// - App init with a user already logged in
// - User login
// - Role switch
// - Impersonation
// - de-impersonation
export const useInitializeUserTracking = () => {
  const currentUser = useCurrentUser();
  const currentRole = useCurrentRole();

  return useEffect(() => {
    if (currentUser) {
      trackIdentify(currentUser.id, {
        ...(currentRole && {
          [getOrganizationRoleResourceTypeKey(currentRole.resource_type)]: currentRole.resource_id,
        }),
        role: currentRole?.kind,
        email: currentUser.email,
        firstName: currentUser.first_name,
        lastName: currentUser.last_name,
        kind: currentUser.kind,
      });
      if (currentRole) {
        trackGroup(currentRole, {
          name: currentRole.resource.name,
          ...('email' in currentRole.resource && { email: currentRole.resource.email }),
          [getOrganizationRoleResourceTypeKey(currentRole.resource_type)]: currentRole.resource_id,
        });
      }
    }
  }, [currentUser, currentRole]);
};
