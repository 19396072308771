import * as Sentry from '@sentry/react';
import { queryClient } from 'api';
import { ProgressBarStyle } from 'components/ProgressBar';
import ScrollToTop from 'components/ScrollToTop';
import MotionLazyContainer from 'components/animate/MotionLazyContainer';
import ThemeSettings from 'components/settings';
import { useCurrentRole, useCurrentUserAndValidateRoles } from 'hooks/slices';
import { useIsTrueUserAdmin } from 'hooks/slices/useCurrentUser';
import { useInitializeUserTracking } from 'hooks/useInitializeUserTracking';
import { useTrackPage } from 'hooks/useTrackPage';
import { useTranslation } from 'locales/i18n';
import moment from 'moment';
import 'moment/locale/fr';
import { useEffect } from 'react';
import 'react-image-lightbox/style.css';
import { QueryClientProvider } from 'react-query';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { selectCurrentUserId, selectUserToken } from 'redux/slices/auth';
import { useSelector } from 'redux/store';
import Router from 'routes';
import ThemeProvider from 'theme';
import { getIntercomSettings } from 'utils/intercom';
import './assets/css/toastify.css';
import intercomImplementation from './intercomImplementation';

export default function App() {
  moment.locale('fr');

  const { t } = useTranslation();
  const location = useLocation();
  const authToken = useSelector(selectUserToken);
  const currentUserId = useSelector(selectCurrentUserId);
  const isTrueUserAdmin = useIsTrueUserAdmin();
  const { currentUser, trueUserId } = useCurrentUserAndValidateRoles() || {};
  const currentRole = useCurrentRole();
  const trackPage = useTrackPage();

  useEffect(() => {
    // Browser support warning.
    // In App.tsx to display the toast only once at the load of the app.
    // For now, only based on presence of IntersectionObserver. Add condition here if needed.
    if (!('IntersectionObserver' in window)) {
      toast.error(t('unsupportedBrowser'), { toastId: 'unsupportedBrowserToast' });
      return;
    }
  }, [t]);

  // INTERCOM INIT
  useEffect(() => {
    intercomImplementation();

    if (isTrueUserAdmin) {
      window.Intercom?.('shutdown');
    } else if (currentUser) {
      window.Intercom?.('boot', getIntercomSettings(currentUser, currentRole));
    } else {
      window.Intercom?.('boot', { app_id: process.env.REACT_APP_INTERCOM_APP_ID });
    }
  }, [isTrueUserAdmin, currentUser, currentRole]);

  // SENTRY INIT
  useEffect(() => {
    if (currentUserId) Sentry.setUser({ id: currentUserId.toString() });
    if (trueUserId) Sentry.setTags({ true_user_id: trueUserId });
  }, [currentUserId, trueUserId]);

  useEffect(() => {
    if (process.env.REACT_APP_SEGMENT_KEY) window.analytics.load(process.env.REACT_APP_SEGMENT_KEY);
  }, []);

  useInitializeUserTracking();

  useEffect(() => {
    if (authToken && currentUser?.id) trackPage(location.pathname);
    // Don't include trackPage because it call the useEffect too many times and send unwanted trackPage
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, authToken, currentUser?.id]);

  return (
    <QueryClientProvider client={queryClient}>
      <MotionLazyContainer>
        <ThemeProvider>
          <ThemeSettings>
            <ProgressBarStyle />
            <ScrollToTop />
            <Router />
            <ToastContainer />
          </ThemeSettings>
        </ThemeProvider>
      </MotionLazyContainer>
    </QueryClientProvider>
  );
}
