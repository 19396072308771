import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { ApiError, GetTopicsParams, TopicsResponse } from 'types/models';
import { faksApiInstance as api } from './api';

export const useGetLabTopics = (
  params?: GetTopicsParams,
  options?: UseQueryOptions<TopicsResponse, AxiosError<ApiError>>
) =>
  useQuery<TopicsResponse, AxiosError<ApiError>>(
    ['topics', params],
    () => api.get<TopicsResponse>('topics', { params }),
    { ...options, enabled: !!params }
  );
