import { useTheme as theme } from '@mui/material/styles';
import { FC } from 'react';
import { SvgIconProps } from 'types/icons';

const BriefCase: FC<SvgIconProps> = ({ color = theme().palette.grey[500], size = 24 }) => (
  <svg width={size} height={size} fill="none" viewBox="0 0 22 22">
    <path
      d="M19 5H3c-1.105 0-2 .831-2 1.857v9.286C1 17.169 1.895 18 3 18h16c1.105 0 2-.831 2-1.857V6.857C21 5.831 20.105 5 19 5Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 18V2.889c0-.501-.21-.982-.586-1.336A2.061 2.061 0 0 0 13 1H9c-.53 0-1.04.199-1.414.553A1.837 1.837 0 0 0 7 2.89V18"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BriefCase;
