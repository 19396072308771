import { Box, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RootStyled = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export const HeaderStyled = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: 22,
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
  },
}));

export const ContentStyled = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export const ActionText = styled('span')(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.primary.main,
  cursor: 'pointer',
}));

export const SubtitleText = styled((props: TypographyProps) => (
  <Typography variant="subtitle1" {...props} />
))(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.grey[600],
  fontWeight: 500,
}));

export const TitleContainerStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 25,
  [theme.breakpoints.up('xs')]: {
    transform: 'none',
    left: 'unset',
    right: 20,
  },
  [theme.breakpoints.up('sm')]: {
    transform: 'translateX(-50%)',
    left: '50%',
    right: 'unset',
  },
}));

export const TitleStyled = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  [theme.breakpoints.up('xs')]: {
    fontSize: '20px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '35px',
  },
}));
