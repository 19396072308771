import { isEmpty, isNil, isNumber } from 'lodash';

export const isEmailValid = (email: string): boolean => {
  const re =
    // eslint-disable-next-line no-useless-escape
    /^[a-z0-9\-\+_]+(\.[a-z0-9\-\+_]+)*\@[a-z0-9\-\+_]+\.[a-z0-9\-\+_]+(\.[a-z0-9\-\+_]+)*$/;

  // eslint-disable-next-line no-control-regex
  const ascii = /^[\x00-\x7F]*$/;

  return re.test(email) && ascii.test(email);
};

export const isZipCodeFormatValid = (zipCode: string): boolean => {
  return zipCode !== undefined && /\d|[A-z]/.test(zipCode);
};

export const isBlank = (value?: number): boolean =>
  isNil(value) || (isEmpty(value) && !isNumber(value)) || isNaN(value);

export const isTagNameValid = (tagName: string): boolean => {
  return /\d|[A-z]/.test(tagName);
};

// source https://stackoverflow.com/a/17773849
export const isUrlValid = (url: string) =>
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/.test(
    url
  );
