import { useCurrentRole } from 'hooks/slices';
import { useIsAdminImpersonating } from 'hooks/slices/useCurrentUser';
import { useSelector } from 'react-redux';
import { selectTrueUserId } from 'redux/slices/auth';
import { getOrganizationRoleResourceTypeKey, trackPage } from 'utils/trackingUtils';

export const useTrackPage = () => {
  const role = useCurrentRole();

  const trueUserId = useSelector(selectTrueUserId);
  const isAdminImpersonating = useIsAdminImpersonating();

  const impersonatingUserId = isAdminImpersonating ? trueUserId : undefined;

  return (locationPathname: string) => {
    trackPage(locationPathname, {
      ...(role
        ? {
            [getOrganizationRoleResourceTypeKey(role.resource_type)]: role.resource_id,
            role: role.kind,
          }
        : {}),
      ...(impersonatingUserId ? { impersonatingUserId } : {}),
    });
  };
};
