import { Group, Lab, Pharmacy, Role, User } from 'types/models';
import { userUtils } from './userUtils';

type IntercomSettings = {
  app_id: string;
  user_id: string;
  email: string | null;
  phone?: string | null;
  name: string | null;
} & changingIntercomSettings;

type changingIntercomSettings = {
  email: string | null;
  kind: string;
  company?: {
    id: string;
    name: string | null;
    phone?: string | null;
    cip?: string | null;
    zip_code?: string | null;
  };
};

const getPharmacySettings = (
  currentPharmacy?: Pharmacy | null
): changingIntercomSettings | undefined =>
  !currentPharmacy
    ? undefined
    : {
        email: currentPharmacy.email,
        kind: 'Pharmacy',
        company: {
          id: `${currentPharmacy.id}`,
          name: currentPharmacy.name,
          phone: currentPharmacy.phone_number,
          cip: currentPharmacy.cip,
          zip_code: currentPharmacy.address_zip_code,
        },
      };

const getGroupSettings = (
  currentUser: User,
  currentGroup?: Group | null
): changingIntercomSettings | undefined =>
  !currentGroup
    ? undefined
    : {
        email: currentUser.email,
        kind: 'Group',
        company: {
          id: `${currentGroup.id}`,
          name: currentGroup.name,
          phone: null,
          cip: null,
          zip_code: null,
        },
      };

const getLabSettings = (
  currentUser: User,
  currentLab?: Lab | null
): changingIntercomSettings | undefined =>
  !currentLab
    ? undefined
    : {
        email: currentUser.email,
        kind: 'Lab',
        company: {
          id: `${currentLab.id}`,
          name: currentLab.name,
          phone: null,
          cip: null,
          zip_code: null,
        },
      };

export const getIntercomSettings = (
  currentUser: User,
  currentRole:
    | (Role & {
        resource: Lab | Pharmacy | Group;
      })
    | null
): IntercomSettings => {
  let data: changingIntercomSettings | undefined;
  if (currentRole?.resource_type === 'Lab')
    data = getLabSettings(currentUser, currentRole.resource as Lab);
  else if (currentRole?.resource_type === 'Pharmacy')
    data = getPharmacySettings(currentRole?.resource as Pharmacy);
  else data = getGroupSettings(currentUser, currentRole?.resource as Group);

  return {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID as string,
    user_id: `${currentUser.id}`,
    name: `${userUtils.getFullName(currentUser)}`,
    phone: currentUser.verified_phone,
    email: data?.email || '',
    kind: data?.kind || '',
    company: data?.company,
  };
};
