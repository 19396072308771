import { useCurrentRole, useCurrentUser } from 'hooks/slices';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { selectUserToken } from 'redux/slices/auth';
import { NavbarBase } from './NavbarBase';
import {
  FaksAdminNavSection,
  GroupNavSection,
  LabNavSection,
  PharmacyNavSection,
  SecuredLabNavSection,
} from './resourceNavSections';

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function Navbar({ isOpenSidebar, onCloseSidebar }: Props) {
  const currentUser = useCurrentUser();
  const currentRole = useCurrentRole();
  const authToken = useSelector(selectUserToken);
  const [searchParams] = useSearchParams();
  const labToken = searchParams.get('labToken');

  return (
    <NavbarBase isOpenSidebar={isOpenSidebar} onCloseSidebar={onCloseSidebar}>
      {currentUser?.kind === 'admin' && <FaksAdminNavSection />}
      {currentRole?.resource_type === 'Lab' && <LabNavSection />}
      {currentRole?.resource_type === 'Pharmacy' && <PharmacyNavSection />}
      {currentRole?.resource_type === 'Group' && <GroupNavSection />}
      {authToken === null && labToken !== null && <SecuredLabNavSection />}
    </NavbarBase>
  );
}
