import { BoxProps } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Image from './Image';

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const logo = (
    <Image alt="Faks_logo" src="/assets/faks_logo.svg" sx={{ width: 85, ml: 2, mr: 1, ...sx }} />
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
