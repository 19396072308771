import { LoadingButton } from '@mui/lab';
import { Box, Button, Link, Typography, useTheme } from '@mui/material';
import { queryClient, useCreateMutation } from 'api';
import { Checkbox } from 'components/Checkbox';
import { BasicModal } from 'components/modals/BasicModal';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
import { useTranslation } from 'locales/i18n';
import { useState } from 'react';
import { signOut } from 'redux/slices/auth';
import { dispatch } from 'redux/store';

export const AcceptCguPage = () => {
  const { t } = useTranslation();
  const [isCguAccepted, setIsCguAccepted] = useState(false);
  const theme = useTheme();

  const acceptCguMutation = useCreateMutation('user/_accept_cgu');

  const acceptCgu = () =>
    acceptCguMutation.mutateAsync({}).then(() => queryClient.invalidateQueries('user/_current'));

  return (
    <LogoOnlyLayout>
      <BasicModal open title={t('acceptCguPage.cgu')}>
        <Box>
          <Checkbox
            checked={isCguAccepted}
            onChange={(_, checked) => setIsCguAccepted(checked)}
            label={
              <Typography fontSize="14px">
                {`${t('acceptCguPage.acceptCgu')} `}
                <Link href="https://www.faks.co/conditions-generales" target="_blank">
                  {t('shared.here')}
                </Link>
                {` ${t('acceptCguPage.personalData')} `}
                <Link href="https://www.faks.co/vie-privee" target="_blank">
                  {t('acceptCguPage.privacyPolicy')}
                </Link>
                {` ${t('acceptCguPage.ofFaks')} `}
              </Typography>
            }
          />
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              onClick={() => dispatch(signOut({}))}
              variant="text"
              sx={{ color: theme.palette.grey[500], mr: 2 }}
            >
              {t('shared.logout')}
            </Button>
            <LoadingButton
              variant="contained"
              onClick={acceptCgu}
              loading={acceptCguMutation.isLoading}
              disabled={!isCguAccepted}
            >
              {t('shared.accept')}
            </LoadingButton>
          </Box>
        </Box>
      </BasicModal>
    </LogoOnlyLayout>
  );
};
