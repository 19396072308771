import { AdminPaths } from './AdminPaths';
import { GroupPaths } from './GroupPaths';
import { LabPaths } from './LabPaths';
import { LoginPaths } from './LoginPaths';
import { OnboarderPaths } from './OnboarderPaths';
import { OnboardingPaths } from './OnboardingPaths';
import { PharmacyPaths } from './PharmacyPaths';
import { SharedPaths } from './SharedPaths';

export * from './AdminPaths';
export * from './GroupPaths';
export * from './LabPaths';
export * from './LoginPaths';
export * from './OnboarderPaths';
export * from './OnboardingPaths';
export * from './PharmacyPaths';
export * from './SharedPaths';

export const allPaths = {
  ...SharedPaths,
  ...AdminPaths,
  ...GroupPaths,
  ...LabPaths,
  ...LoginPaths,
  ...OnboarderPaths,
  ...OnboardingPaths,
  ...PharmacyPaths,
};
