import { AxiosError } from 'axios';
import { serialize } from 'object-to-formdata';
import {
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  useMutation,
  useQuery,
} from 'react-query';
import {
  GetUserResponse,
  UpdatePasswordParams,
  UpdatePasswordResponse,
  UpdateUserParams,
  UpdateUserPasswordParams,
  UserResponse,
} from 'types/api/user';
import { ApiError, faksApiInstance as api } from './api';

export const useGetUserQuery = (options?: UseQueryOptions<GetUserResponse, AxiosError<ApiError>>) =>
  useQuery<GetUserResponse, AxiosError<ApiError>>(
    'user/_current',
    () => api.get<GetUserResponse>('user/_current'),
    options
  );

export const useUpdateUserMutation = (
  options?: UseMutationOptions<UserResponse, AxiosError<ApiError>, UpdateUserParams>
): UseMutationResult<UserResponse, AxiosError<ApiError>, UpdateUserParams> =>
  useMutation<UserResponse, AxiosError<ApiError>, UpdateUserParams>(
    (params) => api.patch<UserResponse>('user', serialize(params)),
    options
  );

export const useUpdatePasswordAfterResetMutation = (
  options?: UseMutationOptions<UpdatePasswordResponse, AxiosError<ApiError>, UpdatePasswordParams>
): UseMutationResult<UpdatePasswordResponse, AxiosError<ApiError>, UpdatePasswordParams> =>
  useMutation<UpdatePasswordResponse, AxiosError<ApiError>, UpdatePasswordParams>((params) =>
    api.patch<UpdatePasswordResponse>('user/update_password_after_reset', params)
  );

export const useUpdateUserPasswordMutation = (
  options?: UseMutationOptions<UserResponse, AxiosError<ApiError>, UpdateUserPasswordParams>
): UseMutationResult<UserResponse, AxiosError<ApiError>, UpdateUserPasswordParams> =>
  useMutation<UserResponse, AxiosError<ApiError>, UpdateUserPasswordParams>(
    (params) => api.patch<UserResponse>('user/update_password', params),
    options
  );
