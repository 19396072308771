import { useCurrentRole } from 'hooks/slices';
import { useIsAdminImpersonating } from 'hooks/slices/useCurrentUser';
import { useSelector } from 'react-redux';
import { selectTrueUserId } from 'redux/slices/auth';
import {
  TrackedActions,
  getOrganizationRoleResourceTypeKey,
  trackEvent,
} from 'utils/trackingUtils';

export const useTrackEvent = () => {
  const role = useCurrentRole();
  const trueUserId = useSelector(selectTrueUserId);
  const isAdminImpersonating = useIsAdminImpersonating();

  const impersonatingUserId = isAdminImpersonating ? trueUserId : undefined;

  return (name: TrackedActions, properties?: Record<string, unknown>) =>
    trackEvent(name, {
      ...(role
        ? {
            [getOrganizationRoleResourceTypeKey(role.resource_type)]: role?.resource_id,
            role: role.kind,
          }
        : {}),
      ...(impersonatingUserId ? { impersonatingUserId } : {}),
      ...properties,
    });
};
