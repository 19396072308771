import { ErrorDescription } from 'types/handled_error';

export const adminHandledErrors: ErrorDescription[] = [
  {
    endpoint: 'admin/pharmacies/merge_pharmacies',
    method: 'post',
    responseStatus: 400,
    error: 'owner_still_present',
  },
  {
    endpoint: 'admin/pharmacies/merge_pharmacies',
    method: 'post',
    responseStatus: 400,
    error: 'roles_still_present',
  },
  {
    endpoint: 'admin/pharmacies/merge_pharmacies',
    method: 'post',
    responseStatus: 400,
    error: 'stripe_client_id_present',
  },
  {
    endpoint: 'admin/pharmacies/merge_pharmacies',
    method: 'post',
    responseStatus: 400,
    error: 'not_same_group',
  },
  {
    endpoint: 'admin/pharmacies/merge_pharmacies',
    method: 'post',
    responseStatus: 400,
    error: 'pharmacy_not_open',
  },
  {
    endpoint: 'admin/pharmacies/merge_pharmacies',
    method: 'post',
    responseStatus: 400,
    error: 'conflicting_external_membership_ids',
  },
  {
    endpoint: 'admin/pharmacies/merge_pharmacies',
    method: 'post',
    responseStatus: 400,
    error: 'not_same_address',
  },
  {
    endpoint: 'admin/pharmacies/merge_pharmacies',
    method: 'post',
    responseStatus: 400,
    error: 'lgo_connection_present',
  },
  {
    endpoint: 'admin/pharmacies/merge_pharmacies',
    method: 'post',
    responseStatus: 400,
    error: 'orders_present',
  },
  {
    endpoint: 'ingestions_tasks',
    method: 'post',
    responseStatus: 415,
    error: 'invalid_encoding',
  },
  {
    endpoint: 'ingestions_tasks',
    method: 'post',
    responseStatus: 422,
    error: 'invalid_file',
  },
  {
    endpoint: 'admin/trades/duplicate_trade_to_group',
    method: 'post',
    responseStatus: 400,
    error: 'group_not_found',
  },
  {
    endpoint: 'admin/trades',
    method: 'post',
    responseStatus: 400,
    error: 'must_be_before_end_date',
  },
  {
    endpoint: 'admin/trades',
    method: 'post',
    responseStatus: 400,
    error: 'same_requirement_name_error',
  },
  {
    endpoint: 'admin/trades',
    method: 'post',
    responseStatus: 409,
    error: 'trades_products_on_trade_id_and_ean',
  },
  {
    endpoint: 'admin/trades',
    method: 'patch',
    responseStatus: 409,
    error: 'trades_products_on_trade_id_and_ean',
  },
  {
    endpoint: 'admin/trades',
    method: 'patch',
    responseStatus: 409,
    error: 'same_requirement_name_error',
  },
  {
    endpoint: 'admin/trades_sellout_items',
    method: 'post',
    responseStatus: 409,
    error: 'tri_ean_unicity_index',
  },
  {
    endpoint: 'admin/expired_products_requests/generate_yousign_procedure',
    method: 'post',
    responseStatus: 400,
    error: 'procedure_generation_failed',
  },
  {
    endpoint: 'admin/pharmacies',
    method: 'patch',
    responseStatus: 409,
    error: 'role_mismatch',
  },
  {
    endpoint: 'admin/groups',
    method: 'post',
    responseStatus: 409,
    error: 'group_name_taken',
  },
  {
    endpoint: 'admin/groups',
    method: 'patch',
    responseStatus: 409,
    error: 'group_name_taken',
  },
  {
    endpoint: 'admin/labs',
    method: 'patch',
    responseStatus: 409,
    error: 'transmission_incompatible_with_the_plan',
  },
  {
    endpoint: 'admin/labs',
    method: 'patch',
    responseStatus: 409,
    error: 'lab_name_taken',
  },
  {
    endpoint: 'admin/labs',
    method: 'patch',
    responseStatus: 409,
    error: 'overwritten_routing_user_empty',
  },
  {
    endpoint: 'admin/labs',
    method: 'patch',
    responseStatus: 422,
    error: 'notifications_recipient_missing',
  },
  {
    endpoint: 'admin/labs',
    method: 'patch',
    responseStatus: 409,
    error: 'payment_method_doesnt_exist',
  },
  {
    endpoint: 'admin/labs',
    method: 'patch',
    responseStatus: 409,
    error: 'payment_client_id_taken',
  },
  {
    endpoint: 'admin/orders',
    method: 'post',
    responseStatus: 409,
    error: 'pharmacy_must_have_a_user',
  },
  {
    endpoint: 'admin/users',
    method: 'post',
    responseStatus: 409,
    error: 'email_already_taken',
  },
  {
    endpoint: 'admin/users',
    method: 'post',
    responseStatus: 409,
    error: 'phone_already_taken',
  },
  {
    endpoint: 'admin/users',
    method: 'patch',
    responseStatus: 409,
    error: 'email_already_taken',
  },
  {
    endpoint: 'admin/users',
    method: 'patch',
    responseStatus: 409,
    error: 'phone_already_taken',
  },
  {
    endpoint: 'admin/users',
    method: 'delete',
    responseStatus: 409,
    error: 'user_cannot_be_deleted',
  },
  {
    endpoint: 'admin/roles',
    method: 'post',
    responseStatus: 409,
    error: 'role_already_exists',
  },
  {
    endpoint: 'admin/roles',
    method: 'post',
    responseStatus: 409,
    error: 'multiple_users_exist',
  },
  {
    endpoint: 'admin/roles',
    method: 'post',
    responseStatus: 409,
    error: 'admins_cant_have_roles',
  },
  {
    endpoint: 'roles',
    method: 'delete',
    responseStatus: 404,
  },
  {
    endpoint: 'admin/pharmacies',
    method: 'patch',
    responseStatus: 500,
    error: 'pharmacy_phone_number_already_taken',
  },
  {
    endpoint: 'admin/pharmacies',
    method: 'post',
    responseStatus: 500,
    error: 'pharmacy_phone_number_already_taken',
  },
  {
    endpoint: 'admin/pharmacies',
    method: 'patch',
    responseStatus: 500,
    error: 'pharmacy_cip_already_taken',
  },
  {
    endpoint: 'admin/pharmacies',
    method: 'post',
    responseStatus: 500,
    error: 'pharmacy_cip_already_taken',
  },
];
