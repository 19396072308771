import { useInfiniteIndexQuery, useShowQuery } from 'api';
import { Autocomplete } from 'components/Autocomplete';
import { useDebounce } from 'hooks/useDebounce';
import { useGetResellerWordingType } from 'hooks/useGetResellerWordingType';
import { useScopedResellerTranslation, useTranslation } from 'locales/i18n';
import { useMemo, useState } from 'react';
import { ResourceType, RoleKind, RoleWithResourceAndUser } from 'types/models';
import { scrollUtils } from 'utils/scrollUtils';
import { userUtils } from 'utils/userUtils';

export type AdminResourceUserPickerProps = {
  resources: { id: number; type: ResourceType }[];
  roleKind?: RoleKind[];
  onChange: (salesRepId: number | null) => void;
  selectedUserId?: number | null;
  placeholder?: string;
  inputParams?: {
    required?: boolean;
    placeholder?: string;
    helperText?: string;
    error?: boolean;
    label?: string;
  };
  required?: boolean;
  directlyEnabled?: boolean;
  isDisabled?: boolean;
};

export const AdminResourceUserPicker = ({
  resources,
  roleKind,
  selectedUserId,
  onChange,
  inputParams,
  placeholder,
  required = false,
  directlyEnabled = false,
  isDisabled,
}: AdminResourceUserPickerProps) => {
  const { t } = useTranslation();
  const { resellerWording } = useGetResellerWordingType();
  const { tScoped } = useScopedResellerTranslation(resellerWording);
  const [searchUserName, setSearchUserName] = useState('');
  const searchUserNameDebounced = useDebounce(searchUserName, 400);
  const [isQueryEnabled, setIsQueryEnabled] = useState(directlyEnabled);

  const useGetRolesQuery = useInfiniteIndexQuery(
    'admin/roles',
    { resources_identifiers: resources, 'q[kind_in]': roleKind, query: searchUserNameDebounced },
    { enabled: isQueryEnabled }
  );

  const { data: selectedUserData } = useShowQuery('admin/users', selectedUserId);

  const usersOptions = useMemo(() => {
    const baseOptions =
      useGetRolesQuery.data?.pages
        .flatMap((page) => page.roles)
        .filter((role) => role.user.id !== selectedUserData?.user.id)
        .map((role: RoleWithResourceAndUser) => ({
          title: `${userUtils.getFullName(role.user)} - (${
            role.resource_type === 'Pharmacy'
              ? tScoped(`resellerRoleKind.${role.kind}`)
              : t(`roleKind.${role.kind}`)
          })`,
          value: role.user.id,
        })) || [];

    return selectedUserData
      ? [
          { title: userUtils.getFullName(selectedUserData.user), value: selectedUserData.user.id },
          ...baseOptions,
        ]
      : baseOptions;
  }, [useGetRolesQuery.data, selectedUserData, tScoped, t]);

  const selectedUser = usersOptions.find((option) => option.value === selectedUserId) || null;

  const beforeOnChange = (value: number | null) => {
    setSearchUserName('');
    onChange(value);
  };

  return (
    <Autocomplete
      onOpen={() => setIsQueryEnabled(true)}
      options={usersOptions}
      loading={useGetRolesQuery.isLoading}
      onChange={(option) => beforeOnChange(option?.value || null)}
      searchedText={searchUserName}
      inputParams={
        // FKS-3017 cleanup this
        inputParams || {
          placeholder: placeholder ?? t('shared.users'),
          required,
        }
      }
      ListboxProps={{
        onScroll: scrollUtils.triggerFetchNextPageOnScrollToBottom(useGetRolesQuery),
      }}
      value={selectedUser}
      onInputChange={setSearchUserName}
      getOptionLabel={(option) => option.title}
      isSearchFilteringHandledExternally
      disabled={isDisabled}
    />
  );
};
