export const DOWNLOAD_LINKS = {
  APP_STORE: 'https://apple.co/3C4IliB',
  PLAY_STORE: 'https://bit.ly/appFaks',
};

export const LGO_LINKS = {
  DATA_POLICY: 'https://www.faks.co/vie-privee',
  INTERCOM: 'https://intercom.help/faks/fr/articles/7984665-connexion-lgo',
  ONBOARDINGS: {
    ospharm: 'https://bienvenue.ospharm.org/8',
    offisante: 'https://offisante.fr/',
    santestat: 'https://www.santestat.fr/',
  },
};

export const INTERCOM_ARTICLES_LINKS = {
  COMPENSATIONS:
    'https://intercom.help/faks/fr/articles/9941904-suivis-des-compensations-des-operations',
  TRADE_PAYMENT:
    'https://intercom.help/faks/fr/articles/7120417-comment-fonctionne-le-paiement-de-mes-operations-sur-faks',
  STRIPE_CONFIG:
    'https://intercom.help/faks/fr/articles/7203127-comment-parametrer-mon-compte-stripe-pour-etre-paye-de-mes-operations',
};

export const ospharmWelcomeLink = (cip: string | null) =>
  cip ? `https://bienvenue.ospharm.org/8/${cip}` : 'https://bienvenue.ospharm.org/8';

export const openInNewTab = (link: string) => window.open(link, '_blank');
