import { useTheme as theme } from '@mui/material/styles';
import { FC } from 'react';
import { SvgIconProps } from 'types/icons';

const User: FC<SvgIconProps> = ({ color = theme().palette.grey[500], size = 24 }) => (
  <svg width={size} height={size} fill="none" viewBox="0 0 22 22">
    <path
      d="M2.063 20.625s-1.688 0-1.688-1.688c0-1.687 1.688-6.75 10.125-6.75 8.438 0 10.125 5.063 10.125 6.75 0 1.688-1.688 1.688-1.688 1.688H2.063ZM10.5 10.5a5.062 5.062 0 1 0 0-10.125 5.062 5.062 0 0 0 0 10.125Z"
      fill={color}
    />
  </svg>
);

export default User;
