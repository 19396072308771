import { styled } from '@mui/material/styles';

export const NotifBadge = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.common.white,
  fontWeight: 700,
  borderRadius: '5px',
  padding: '3px 8px',
  fontSize: '12px',
  marginRight: '10px',
  lineHeight: '12px',
}));
