import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { GetStatisticsParams, LabStatisticsResponse } from 'types/api/labs/lab_trade_instances';
import { ApiError, faksApiInstance as api } from './api';

export const useGetLabStatisticsQuery = (
  params?: GetStatisticsParams,
  options?: UseQueryOptions<LabStatisticsResponse, AxiosError<ApiError>>
) =>
  useQuery<LabStatisticsResponse, AxiosError<ApiError>>(
    ['labs/trades_instances/statistics', params],
    () => api.get<LabStatisticsResponse>('labs/trades_instances/statistics', { params }),
    {
      enabled: !!params,
      ...options,
    }
  );
