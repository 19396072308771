import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { useCreateMutation } from 'api';
import { PhoneInputField } from 'components/PhoneInputField';
import { FormProvider } from 'components/hook-form';
import { useTranslation } from 'locales/i18n';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { setPhoneNumber, setPhoneToken } from 'redux/slices/auth';
import { LoginPaths } from 'routes/paths';
import { navigateToIdentityProvider } from 'utils/navigationUtils';
import { validatePhoneNumber } from 'utils/phoneUtils';
import * as Yup from 'yup';

type FormValuesProps = {
  phoneNumber: string;
  code: string;
  afterSubmit?: string;
};

export const Form: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const redirectionUrl = useOutletContext<string>();

  const LoginSchema = Yup.object().shape({
    phoneNumber: Yup.string().test(
      'phoneNumber',
      t('login.verify_phone_number.form.phone_number_format_error'),
      validatePhoneNumber
    ),
  });

  const defaultValues = {
    phoneNumber: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const { handleSubmit, getValues } = methods;

  const initiateAuthWithPhoneMutation = useCreateMutation('initiate_auth_with_phone');

  const onSubmit = async (data: FormValuesProps) => {
    initiateAuthWithPhoneMutation
      .mutateAsync({ phone_number: data.phoneNumber })
      .then((response) => {
        if (response.success) {
          if (response.identity_provider_url != null) {
            navigateToIdentityProvider(response.identity_provider_url, redirectionUrl);
          } else {
            dispatch(setPhoneToken(response.phone_token));
            dispatch(setPhoneNumber(getValues('phoneNumber')));
            navigate(LoginPaths.Code);
          }
        } else {
          throw new Error(response.reason);
        }
      });
  };

  const submitOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleSubmit(onSubmit)();
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <PhoneInputField
          label={t('login.verify_phone_number.form.your_phone_number')}
          onKeyDown={submitOnEnter}
          dropdownStyle={{
            maxHeight: '200px',
          }}
        />
      </Stack>
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={initiateAuthWithPhoneMutation.isLoading}
        sx={{ mt: 3 }}
      >
        {t('shared.connect')}
      </LoadingButton>
    </FormProvider>
  );
};
