import { useTheme as theme } from '@mui/material/styles';
import { FC } from 'react';
import { SvgIconProps } from 'types/icons';

const Message: FC<SvgIconProps> = ({ color = theme().palette.grey[500], size = 24 }) => (
  <svg width={size} height={size} fill={color} viewBox="0 0 24 22">
    <path d="M7 7h10v2H7V7Zm0 4h7v2H7v-2Z" fill={color} />
    <path
      d="M20 2H4c-1.103 0-2 .897-2 2v18l5.333-4H20c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2Zm0 14H6.667L4 18V4h16v12Z"
      fill={color}
    />
  </svg>
);

export default Message;
