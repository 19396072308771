import { Box, List } from '@mui/material';
import { NavListRoot } from 'components/nav-section/NavList';
import { NavListProps } from 'components/nav-section/type';

type Props = { navItems: NavListProps[] };

export const NavSection = ({ navItems }: Props) => {
  return (
    <Box>
      <List disablePadding sx={{ px: 2 }}>
        {navItems.map((navItem) => (
          <NavListRoot key={navItem.title + navItem.path} list={navItem} />
        ))}
      </List>
    </Box>
  );
};
