import { useTheme as theme } from '@mui/material/styles';
import { FC } from 'react';
import { SvgIconProps } from 'types/icons';

export const Check: FC<SvgIconProps> = ({ color = theme().palette.grey[500], size = 24 }) => (
  <svg width={size} height={size} fill="none" viewBox="0 0 448 512">
    <path
      d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7l233.4-233.3c12.5-12.5 32.8-12.5 45.3 0z"
      fill={color}
    />
  </svg>
);

export default Check;
