import { AxiosError, AxiosRequestConfig } from 'axios';
import { saveAs } from 'file-saver';
import { useOnErrorNavigation } from 'hooks/useOnErrorNavigation';
import { serialize } from 'object-to-formdata';
import {
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { Endpoints } from 'types';
import { ApiError, faksApiInstance } from './api';

type EnhancedRoute<Route extends keyof Endpoints> = Endpoints[Route]['PATH_PARAMS'] extends never
  ? Route
  : {
      route: Route;
      pathParams: Endpoints[Route]['PATH_PARAMS'];
    };

export const applyPathParams = <Route extends keyof Endpoints>(route: EnhancedRoute<Route>) => {
  if (typeof route === 'string') return route;

  let finalRoute: string = route.route;
  Object.entries(route.pathParams).forEach(([key, value]) => {
    finalRoute = finalRoute.replace(`:${key}`, value.toString());
  });

  return finalRoute;
};

export const keys = <Route extends keyof Endpoints>(route: EnhancedRoute<Route>) => ({
  all: [route] as const,
  //lists: [route, 'list'] as const,
  list: <T extends 'paginated' | 'infinite'>(
    type: T,
    params?: T extends 'infinite'
      ? Omit<Endpoints[Route]['INDEX']['params'], 'page'>
      : Endpoints[Route]['INDEX']['params']
  ) => [route, 'list', { type }, params] as const,
  statistics: (params?: Endpoints[Route]['STATISTICS']['params']) =>
    [route, 'statistics', params] as const,
  //details: [route , 'detail'] as const,
  detail: (id: number | null | undefined) => [route, 'detail', id] as const,
});

export const useInfiniteIndexQuery = <Route extends keyof Endpoints>(
  route: EnhancedRoute<Route>,
  params?: Omit<Endpoints[Route]['INDEX']['params'], 'page'>,
  options?: UseInfiniteQueryOptions<
    Endpoints[Route]['INDEX']['response'],
    AxiosError<ApiError>,
    Endpoints[Route]['INDEX']['response'],
    Endpoints[Route]['INDEX']['response'],
    readonly [
      EnhancedRoute<Route>,
      'list',
      { type: 'infinite' },
      Omit<Endpoints[Route]['INDEX']['params'], 'page'> | undefined,
    ]
  >
) => {
  const finalRoute = applyPathParams(route);

  return useInfiniteQuery(
    keys(route).list('infinite', params),
    ({ pageParam = 1 }) =>
      faksApiInstance.get<Endpoints[Route]['INDEX']['response']>(finalRoute, {
        params: { page: pageParam, ...params },
      }),
    {
      enabled: !!params,
      getNextPageParam: (response) => {
        if (!('meta' in response)) throw new Error('Endpoint should be paginated');

        return response.meta.current_page >= response.meta.total_pages
          ? undefined
          : response.meta.current_page + 1;
      },
      ...options,
    }
  );
};

export const useIndexQuery = <Route extends keyof Endpoints>(
  route: EnhancedRoute<Route>,
  params?: Endpoints[Route]['INDEX']['params'],
  options?: UseQueryOptions<
    Endpoints[Route]['INDEX']['response'],
    AxiosError<ApiError>,
    Endpoints[Route]['INDEX']['response'],
    readonly [
      EnhancedRoute<Route>,
      'list',
      { type: 'paginated' },
      Endpoints[Route]['INDEX']['params'] | undefined,
    ]
  >
) => {
  const finalRoute = applyPathParams(route);

  return useQuery(
    keys(route).list('paginated', params),
    () => faksApiInstance.get<Endpoints[Route]['INDEX']['response']>(finalRoute, { params }),
    { enabled: !!params, ...options }
  );
};

export const useStatisticsQuery = <Route extends keyof Endpoints>(
  route: EnhancedRoute<Route>,
  params?: Endpoints[Route]['STATISTICS']['params'],
  options?: UseQueryOptions<
    Endpoints[Route]['STATISTICS']['response'],
    AxiosError<ApiError>,
    Endpoints[Route]['STATISTICS']['response'],
    readonly [
      EnhancedRoute<Route>,
      'statistics',
      Endpoints[Route]['STATISTICS']['params'] | undefined,
    ]
  >
): UseQueryResult<Endpoints[Route]['STATISTICS']['response'], AxiosError<ApiError>> => {
  const finalRoute = applyPathParams(route);

  return useQuery(
    keys(route).statistics(params),
    () =>
      faksApiInstance.get<Endpoints[Route]['STATISTICS']['response']>(`${finalRoute}/statistics`, {
        params,
      }),
    { enabled: !!params, ...options }
  );
};

export const useShowQuery = <Route extends keyof Endpoints>(
  route: EnhancedRoute<Route>,
  id: number | undefined | null,
  options?: UseQueryOptions<
    Endpoints[Route]['SHOW']['response'],
    AxiosError<ApiError>,
    Endpoints[Route]['SHOW']['response'],
    readonly [EnhancedRoute<Route>, 'detail', number | null | undefined]
  >,
  navigateOnError?: boolean
) => {
  const { handleErrorNavigation } = useOnErrorNavigation();
  const finalRoute = applyPathParams(route);

  return useQuery(
    keys(route).detail(id),
    () => faksApiInstance.get<Endpoints[Route]['SHOW']['response']>(`${finalRoute}/${id}`),
    {
      enabled: !!id,
      ...options,
      onError: (error) => {
        options?.onError?.(error);
        if (navigateOnError) handleErrorNavigation(error.response?.status);
      },
    }
  );
};

export const useCreateMutation = <Route extends keyof Endpoints>(
  route: EnhancedRoute<Route>,
  options?: UseMutationOptions<
    Endpoints[Route]['CREATE']['response'],
    AxiosError<ApiError>,
    Endpoints[Route]['CREATE']['params']
  >,
  needsSerialization?: boolean,
  config?: AxiosRequestConfig
): UseMutationResult<
  Endpoints[Route]['CREATE']['response'],
  AxiosError<ApiError>,
  Endpoints[Route]['CREATE']['params']
> => {
  const client = useQueryClient();
  const finalRoute = applyPathParams(route);

  return useMutation<
    Endpoints[Route]['CREATE']['response'],
    AxiosError<ApiError>,
    Endpoints[Route]['CREATE']['params']
  >(
    (params) =>
      faksApiInstance.post<Endpoints[Route]['CREATE']['response']>(
        finalRoute,
        needsSerialization ? serialize(params) : params,
        config
      ),
    {
      ...options,
      onSuccess: (data, ...args) => {
        options?.onSuccess?.(data, ...args);
        client.invalidateQueries(keys(route).all);
      },
    }
  );
};

export const useExportMutation = <Route extends keyof Endpoints>(
  route: EnhancedRoute<Route>,
  filename?: string,
  options?: UseMutationOptions<
    Endpoints[Route]['EXPORT']['response'],
    AxiosError<ApiError>,
    Endpoints[Route]['EXPORT']['params']
  >
): UseMutationResult<
  Endpoints[Route]['EXPORT']['response'],
  AxiosError<ApiError>,
  Endpoints[Route]['EXPORT']['params']
> => {
  const finalRoute = applyPathParams(route);

  return useMutation<
    Endpoints[Route]['EXPORT']['response'],
    AxiosError<ApiError>,
    Endpoints[Route]['EXPORT']['params']
  >(
    (params) => faksApiInstance.get<Endpoints[Route]['EXPORT']['response']>(finalRoute, { params }),
    {
      ...options,
      onSuccess: (data, ...args) => {
        saveAs('data:application/octet-stream;base64,' + data.file, filename || data.filename);
        options?.onSuccess?.(data, ...args);
      },
    }
  );
};

export const useUpdateMutation = <Route extends keyof Endpoints>(
  route: EnhancedRoute<Route>,
  options?: UseMutationOptions<
    Endpoints[Route]['UPDATE']['response'],
    AxiosError<ApiError>,
    Endpoints[Route]['UPDATE']['params']
  >
): UseMutationResult<
  Endpoints[Route]['UPDATE']['response'],
  AxiosError<ApiError>,
  Endpoints[Route]['UPDATE']['params']
> => {
  const client = useQueryClient();
  const finalRoute = applyPathParams(route);

  return useMutation<
    Endpoints[Route]['UPDATE']['response'],
    AxiosError<ApiError>,
    Endpoints[Route]['UPDATE']['params']
  >(
    (params) =>
      faksApiInstance.patch<Endpoints[Route]['UPDATE']['response']>(
        params.id ? `${finalRoute}/${params.id}` : finalRoute,
        params.options?.needsSerialization ? serialize(params.fields) : params.fields
      ),
    {
      ...options,
      onSuccess: (data, ...args) => {
        client.invalidateQueries(keys(route).all);
        options?.onSuccess?.(data, ...args);
      },
    }
  );
};

export const useDestroyMutation = <Route extends keyof Endpoints>(
  route: EnhancedRoute<Route>,
  options?: UseMutationOptions<
    Endpoints[Route]['DESTROY']['response'],
    AxiosError<ApiError>,
    number
  >
): UseMutationResult<Endpoints[Route]['DESTROY']['response'], AxiosError<ApiError>, number> => {
  const client = useQueryClient();
  const finalRoute = applyPathParams(route);

  return useMutation<Endpoints[Route]['DESTROY']['response'], AxiosError<ApiError>, number>(
    (id) => faksApiInstance.delete<Endpoints[Route]['DESTROY']['response']>(`${finalRoute}/${id}`),
    {
      ...options,
      onSuccess: (data, ...args) => {
        // defer allows to call instructions before invalidation refetchs,
        // for example, allow navigation before refetch to prevent 404 errors)
        setTimeout(() => client.invalidateQueries(keys(route).all), 1);
        options?.onSuccess?.(data, ...args);
      },
    }
  );
};
