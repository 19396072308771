import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import { memo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PhoneInput, { CountryData, PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { phoneNumberFormat, supportedPhoneCountries } from 'utils/phoneUtils';

export type PhoneInputFieldProps = PhoneInputProps & {
  label: string;
  name?: string;
  isRequired?: boolean;
};

export const PhoneInputField = memo<PhoneInputFieldProps>(
  ({ label, isRequired = true, name = 'phoneNumber', ...props }) => {
    const { control, setValue } = useFormContext();
    const theme = useTheme();
    const [currentDialCode, setCurrentDialCode] = useState<string | null>(null);

    return (
      <Controller
        control={control}
        name={name}
        rules={{ required: isRequired }}
        render={({ field: { ref, ...field }, fieldState: { error } }) => {
          return (
            <Box display="flex" flexDirection="column" width="100%">
              <PhoneInput
                {...field}
                inputProps={{
                  ref,
                  required: isRequired,
                  name: name,
                }}
                isValid={() => isEmpty(error)}
                value={!field.value && currentDialCode ? '+' + currentDialCode : field.value}
                onChange={(value: string, countryData: CountryData) => {
                  const newVal = value === countryData.dialCode ? '' : '+' + value;
                  setCurrentDialCode(countryData.dialCode);
                  setValue(name, newVal);
                }}
                specialLabel={isEmpty(error) ? label : ''}
                onlyCountries={supportedPhoneCountries}
                masks={phoneNumberFormat}
                localization={{ re: 'Réunion / Mayotte' }}
                country={'fr'}
                enableTerritories={true}
                enableAreaCodes={true}
                countryCodeEditable={false}
                inputStyle={{
                  width: '100%',
                  borderColor: error ? theme.palette.error.main : 'lightgrey',
                }}
                {...props}
              />
              {error && (
                <Typography color={theme.palette.error.main} fontSize="0.75rem" sx={{ mt: '8px' }}>
                  {error.message}
                </Typography>
              )}
            </Box>
          );
        }}
      />
    );
  }
);
