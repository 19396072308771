import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { queryClient } from 'api';
import queryString from 'query-string';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import 'react-image-lightbox/style.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'redux/store';
import 'simplebar/src/simplebar.css';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import App from './App';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { SettingsProvider } from './contexts/SettingsContext';
import './locales/i18n';

Sentry.init({
  dsn: 'https://038edc12b01746a883146e16866b7fc0@o412508.ingest.sentry.io/6459649',
  integrations: [
    new BrowserTracing(),
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
    }),
  ],
  tracesSampleRate: 0.3,
  environment: process.env.REACT_APP_ENV,
  enabled: process.env.REACT_APP_ENV !== 'development',
  ignoreErrors: ['globalThis is not defined'],
  replaysOnErrorSampleRate: 1.0,
  beforeSend: (event, hint) => {
    if (hint.originalException instanceof Error) {
      const exception = hint.originalException;

      if ('isAxiosError' in exception && exception.isAxiosError) {
        return null;
      }
    }

    return event;
  },
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <HelmetProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <SettingsProvider>
            <CollapseDrawerProvider>
              <BrowserRouter>
                <QueryParamProvider
                  adapter={ReactRouter6Adapter}
                  options={{
                    searchStringToObject: queryString.parse,
                    objectToSearchString: queryString.stringify,
                  }}
                >
                  <App />
                </QueryParamProvider>
              </BrowserRouter>
            </CollapseDrawerProvider>
          </SettingsProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </HelmetProvider>
);
