import { useIndexQuery } from 'api';
import { Pharmacy, ResellerWording } from 'types/models';
import { resellerWordingMapping } from 'utils/resellerUtils';
import { _5_MINUTES_IN_MS } from '../constants';
import { useCurrentRole } from './slices';

type ReturnType = {
  resellerWording: ResellerWording | undefined;
  isLoading: boolean;
};

export const useGetResellerWordingType = (): ReturnType => {
  const currentRole = useCurrentRole();
  const isCurrentResourceLab = currentRole?.resource_type === 'Lab';

  const { data: establishmentTypeData, isLoading: isEstablishmentTypeLoading } = useIndexQuery(
    'labs/lab/reseller_wording_type',
    undefined,
    { enabled: isCurrentResourceLab && !!currentRole, staleTime: _5_MINUTES_IN_MS }
  );

  if (currentRole?.resource_type === 'Pharmacy') {
    const pharmacy = currentRole.resource as Pharmacy;

    return {
      resellerWording: resellerWordingMapping(pharmacy.kind),
      isLoading: false,
    };
  }

  return {
    resellerWording: establishmentTypeData?.reseller_wording_type,
    isLoading: isEstablishmentTypeLoading,
  };
};
