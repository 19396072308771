import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Avatar, Box, Divider, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import MenuPopover from 'components/MenuPopover';
import { IconButtonAnimate } from 'components/animate';
import RoundedExclamation from 'components/icons/RoundedExclamation';
import { useCurrentResource, useCurrentRole, useCurrentUser } from 'hooks/slices';
import { useIsCurrentUserAdmin } from 'hooks/slices/useCurrentUser';
import { useGetResellerWordingType } from 'hooks/useGetResellerWordingType';
import NavbarCard from 'layouts/dashboard/navbar/NavbarCard';
import { useScopedResellerTranslation, useTranslation } from 'locales/i18n';
import { includes } from 'lodash';
import { PanelistConnectionStatusIcon } from 'pages/pharmacy/PanelistConnection/PanelistStatusComponents';
import { useGetPanelistConnectionStatus } from 'pages/pharmacy/PanelistConnection/PharmacyPanelistConnectionPage';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCurrentRoleId, signOut } from 'redux/slices/auth';
import { LoginPaths, PharmacyPaths, SharedPaths } from 'routes/paths';
import { ResourceType, RoleWithResource } from 'types/models';
import {
  DIVIDERS_HEIGHT,
  LOGOUT_HEIGHT,
  NAVBAR_PADDING_Y,
  POPOVER_HEIGHT,
  PROFILE_NAVCARD_HEIGHT,
  USER_NAVCARD_HEIGHT,
} from 'utils/accountPopoverUtils';
import { formatPhoneNumberWithCountryCode } from 'utils/phoneUtils';
import { userUtils } from 'utils/userUtils';

const POPOVER_RESERVED_HEIGHT =
  NAVBAR_PADDING_Y +
  POPOVER_HEIGHT +
  DIVIDERS_HEIGHT +
  USER_NAVCARD_HEIGHT +
  PROFILE_NAVCARD_HEIGHT +
  LOGOUT_HEIGHT;

export default function AccountPopover() {
  const { t } = useTranslation();
  const { resellerWording } = useGetResellerWordingType();
  const { tScoped } = useScopedResellerTranslation(resellerWording);
  const theme = useTheme();
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const isUserAdmin = useIsCurrentUserAdmin();
  const currentRole = useCurrentRole();
  const currentResource = useCurrentResource();

  const shouldDisplayGroupSettings =
    currentRole && currentRole.resource_type === 'Group' && currentRole.kind === 'group_admin';

  const getResourceType = (resourceType: ResourceType) => {
    switch (resourceType) {
      case 'Group':
        return t('shared.group');
      case 'Lab':
        return t('shared.lab');
      case 'Pharmacy':
        return t('shared.pharmacy');
      default:
        return '';
    }
  };

  const roles = currentUser?.roles || [];

  const shouldDisplayStripeMenuItem =
    currentResource &&
    'stripe_account_status' in currentResource &&
    currentResource.stripe_account_status !== 'none';

  const hasStripeNotification =
    shouldDisplayStripeMenuItem &&
    includes(['incomplete', 'can_create_account'], currentResource.stripe_account_status);

  const { panelistConnection, isPharmacyAllowedToEstablishConnection, panelist } =
    useGetPanelistConnectionStatus();

  const hasPanelistConnectionNotification =
    currentRole?.resource_type === 'Pharmacy' &&
    isPharmacyAllowedToEstablishConnection &&
    !panelist;

  const MENU_OPTIONS = [
    ...(currentRole ? [{ label: t('account.profile'), linkTo: SharedPaths.EditCurrentUser }] : []),
    ...(currentRole && currentRole.resource_type === 'Pharmacy'
      ? [
          { label: tScoped('myReseller'), linkTo: PharmacyPaths.EditCurrentPharmacy },
          ...(resellerWording === 'pharmacy'
            ? [
                {
                  label: t('shared.myGroup'),
                  linkTo: PharmacyPaths.Group,
                },
              ]
            : []),
          ...(shouldDisplayStripeMenuItem
            ? [
                {
                  label: t('shared.paymentAndInvoices'),
                  linkTo: PharmacyPaths.Stripe,
                  rightIcon:
                    currentResource.stripe_account_status === 'ok' ? (
                      <CheckCircleIcon
                        fontSize="small"
                        sx={{ color: theme.palette.success.dark }}
                      />
                    ) : (
                      <RoundedExclamation size={16} color={theme.palette.warning.main} />
                    ),
                },
              ]
            : []),
          ...(isPharmacyAllowedToEstablishConnection
            ? [
                {
                  label: t('shared.panelistConnection'),
                  linkTo: PharmacyPaths.LgoSetting,
                  rightIcon: panelistConnection && (
                    <PanelistConnectionStatusIcon
                      status={panelistConnection.status}
                      fontSize="small"
                    />
                  ),
                },
              ]
            : []),
        ]
      : []),
    ...(shouldDisplayGroupSettings
      ? [
          {
            label: t('shared.settings'),
            linkTo: `/${currentRole.resource_type.toLowerCase()}/${currentRole.resource_id}/edit`,
          },
        ]
      : []), // This condition is temporary for now
  ];

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleSwitchRole = (roleItem: RoleWithResource) => {
    if (roleItem.id === currentRole?.id) return;
    dispatch(setCurrentRoleId(roleItem.id));
    navigate(`/`);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 2,
          width: '100%',
          color: 'inherit',
          textAlign: 'initial',
          borderRadius: '10px',
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '10px',
              position: 'absolute',
              bgcolor: (themeItem) => alpha(themeItem.palette.grey[900], 0.2),
            },
          }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Box position="relative">
              <Avatar
                src={currentUser?.profile_picture_url || ''}
                alt={currentUser?.first_name || ''}
              />
              {(hasStripeNotification || hasPanelistConnectionNotification) && (
                <Box sx={{ position: 'absolute', bottom: '-4px', right: '-4px' }}>
                  <RoundedExclamation size={16} color={theme.palette.warning.main} />
                </Box>
              )}
            </Box>
            <Box>
              <Typography variant="subtitle2">{currentResource?.name}</Typography>
              <Typography variant="body2" color="GrayText">
                {userUtils.getFullName(currentUser)}
              </Typography>
            </Box>
          </Stack>
          <UnfoldMoreIcon
            data-testid="OpenAccountPopover"
            fontSize="large"
            sx={{ color: theme.palette.grey[500] }}
          />
        </Stack>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        arrow="bottom-center"
        sx={{ p: 0, minWidth: '247px' }}
      >
        {currentUser && (
          <NavbarCard
            title={`${currentUser.first_name} ${currentUser.last_name}` || ''}
            subtitle={
              currentUser.verified_phone
                ? formatPhoneNumberWithCountryCode(currentUser.verified_phone)
                : ''
            }
          />
        )}
        {currentRole || isUserAdmin ? (
          <>
            <Divider />

            <Stack sx={{ p: 1 }}>
              {MENU_OPTIONS.map((option) => (
                <MenuItem
                  key={option.label}
                  onClick={() => {
                    handleClose();
                    navigate(option.linkTo);
                  }}
                >
                  {option.label}
                  {option.rightIcon && (
                    <Box ml={1} display="flex" alignItems="center">
                      {option.rightIcon}
                    </Box>
                  )}
                </MenuItem>
              ))}
            </Stack>

            <Divider />

            <Stack
              sx={{ maxHeight: `calc(100vh - ${POPOVER_RESERVED_HEIGHT}px)`, overflowY: 'scroll' }}
            >
              {roles.map((role, index) => (
                <MenuItem
                  key={index}
                  sx={{ m: 1, justifyContent: 'space-between' }}
                  onClick={() => handleSwitchRole(role)}
                >
                  <Box>
                    <Typography variant="subtitle2">{role.resource.name}</Typography>
                    <Typography variant="body2" color="GrayText">
                      {t('account.switchTo')} {getResourceType(role.resource_type).toLowerCase()}
                    </Typography>
                  </Box>
                  {role.id === currentRole?.id && (
                    <CheckIcon style={{ fill: theme.palette.success.main }} />
                  )}
                </MenuItem>
              ))}
            </Stack>

            <Divider />

            <MenuItem sx={{ m: 1 }} onClick={() => dispatch(signOut({ userInitiated: true }))}>
              {t('account.logout')}
            </MenuItem>
          </>
        ) : (
          <MenuItem sx={{ m: 1 }} onClick={() => navigate(LoginPaths.PhoneNumber)}>
            {t('shared.connect')}
          </MenuItem>
        )}
      </MenuPopover>
    </>
  );
}
