import { TextField as MUITextField, TextFieldProps } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type IProps = {
  name: string;
};

type Props = IProps & TextFieldProps;

export const TextField: FC<Props> = ({ name, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <MUITextField
          {...field}
          fullWidth
          {...other}
          error={!!error}
          helperText={error?.message || other.helperText}
        />
      )}
    />
  );
};
