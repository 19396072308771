export enum OnboardingPaths {
  PharmacistOrLab = '/onboarding/pharmacist_or_lab',
  LabUnavailable = '/onboarding/lab_unavailable',
  CreateProfile = '/onboarding/create_profile',
  SearchPharmacy = '/onboarding/search_pharamacy',
  CreatePharmacy = '/onboarding/create_pharmacy',
  JoinPharmacy = '/onboarding/join_pharmacy',
  VerifyEmail = '/onboarding/verify_email',
  VerifyAccount = '/onboarding/verify_account',
}
