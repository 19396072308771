import { GroupRole, LabRole, PharmacyRole, Role, RoleKind } from 'types/models';

export const isGroupRole = (role: Role): role is GroupRole => role.resource_type === 'Group';

export const isLabRole = (role: Role): role is LabRole => role.resource_type === 'Lab';

export const isPharmacyRole = (role: Role): role is PharmacyRole =>
  role.resource_type === 'Pharmacy';

export const isSalesRep = (role?: RoleKind): boolean => 'lab_sales_rep' === role;

export const isSalesRepNoAccess = (role?: RoleKind): boolean => 'lab_sales_rep_no_access' === role;

export const isLabAdmin = (role?: RoleKind): boolean => 'lab_admin' === role;

export const shouldShowLabUserPicker = (roleKind: RoleKind): boolean =>
  roleKind === 'lab_admin' || roleKind === 'lab_regional_director';
