export enum SharedPaths {
  // Errors
  NotFound = '/404',
  Unauthorized = '/403',
  InvalidToken = '/498',
  NoAccess = '/no-access',

  // Common
  EditCurrentUser = '/user/profile',
  StripeState = '/stripe/state',

  // External
  IntercomFAQ = 'https://intercom.help/faks/fr',
}
