import { LoadingButton } from '@mui/lab';
import { OutlinedInput, Stack, Typography } from '@mui/material';
import { useCreateMutation } from 'api';
import { FormProvider } from 'components/hook-form';
import { useOnLogin } from 'hooks/useOnLogin';
import { useTranslation } from 'locales/i18n';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectPhoneNumber, selectPhoneToken, setPhoneToken } from 'redux/slices/auth';
import { ActionText } from '../styles';

type FormValuesProps = {
  code: string[];
};

export default function Form() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const phoneToken = useSelector(selectPhoneToken);
  const phoneNumber = useSelector(selectPhoneNumber);
  const onLogin = useOnLogin();

  const [timerSeconds, setTimerSeconds] = useState(0);

  const defaultValues = {
    code: Array(6).join('.').split('.'),
  };

  const methods = useForm({
    mode: 'all',
    defaultValues,
  });

  const { watch, setValue, handleSubmit } = methods;

  const values = watch();

  const handleChangeWithNextField = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const codeTmp = [...values.code];
    const { maxLength, value, name } = event.target;
    const fieldIndex = name.replace('code.', '');
    const fieldIntIndex = Number(fieldIndex);

    if (value.length >= maxLength) {
      if (fieldIntIndex < 6) {
        const nextfield = document.querySelector(`input[name='code.${fieldIntIndex + 1}']`);

        if (nextfield !== null) {
          (nextfield as HTMLElement).focus();
        }
      }
    }

    codeTmp[index] = value;
    setValue('code', codeTmp);
  };

  const initiateAuthWithPhoneMutation = useCreateMutation('initiate_auth_with_phone', {
    onSuccess: (data) => {
      if (data.success && data.phone_token) {
        // Should be always true here as it was already checked in the previous screen
        dispatch(setPhoneToken(data.phone_token));
      }
    },
  });

  const authenticateWithPhoneAndCodeMutation = useCreateMutation(
    'authenticate_with_phone_and_code'
  );

  const onResendCode = async () => {
    if (!phoneNumber) return;
    initiateAuthWithPhoneMutation.mutateAsync({ phone_number: phoneNumber }).then(() => {
      setTimerSeconds(10);
    });
  };

  const onSubmit = async (data: FormValuesProps) => {
    if (phoneToken)
      authenticateWithPhoneAndCodeMutation
        .mutateAsync({ phone_token: phoneToken, code: data.code.join('') })
        .then((data) => {
          if (data.success) {
            onLogin(data.auth_token);
          } else {
            if (data.reason === 'invalid_credentials') {
              toast.error(t('login.verify_code.form.code_not_valid_error'));
            } else if (data.reason === 'user_deactivated') {
              toast.error(t('login.userDeactivated'));
            }
            setValue('code', defaultValues.code);
          }
        });
  };

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (timerSeconds > 0) {
      timer = setTimeout(() => {
        setTimerSeconds(timerSeconds - 1);
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [timerSeconds]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack direction="row" spacing={2} justifyContent="center">
          {values.code.map((name, index) => (
            <Controller
              key={index}
              name={`code.${index}`}
              render={({ field, fieldState: { error } }) => (
                <OutlinedInput
                  {...field}
                  value={name}
                  error={!!error}
                  autoFocus={index === 0}
                  placeholder="-"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeWithNextField(event, index)
                  }
                  inputProps={{
                    className: 'field-code',
                    maxLength: 1,
                    sx: {
                      p: 0,
                      textAlign: 'center',
                      width: { xs: 36, sm: 56 },
                      height: { xs: 36, sm: 56 },
                    },
                  }}
                />
              )}
            />
          ))}
        </Stack>
        <Typography align="center">
          {timerSeconds === 0 ? (
            <>
              {t('login.verify_code.noCodeReceived')}{' '}
              <ActionText onClick={onResendCode}>{t('login.verify_code.resendCode')}</ActionText>
            </>
          ) : (
            <ActionText style={{ opacity: '.5' }}>
              {t('login.verify_code.resendCodeCountdown', { timeLeft: timerSeconds })}
            </ActionText>
          )}
        </Typography>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={authenticateWithPhoneAndCodeMutation.isLoading}
          sx={{ mt: 3 }}
        >
          {t('shared.connect')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
