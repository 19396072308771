import { LoadingButton } from '@mui/lab';
import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material';
import { ReactNode } from 'react';
import { ColorSchema } from 'theme/palette';
import Iconify from './Iconify';
import { IconButtonAnimate } from './animate';

interface Props {
  open?: boolean;
  handleClose: () => void;
  title: string;
  description: string | ReactNode;
  cancelButton?: {
    label: string;
    action: () => void;
  };
  actionButton: {
    label: string | ReactNode;
    action: () => void;
    isLoading?: boolean;
    color?: ColorSchema;
    disabled?: boolean;
  };
  canExtendToFullWidth?: boolean;
  maxWidth?: Breakpoint;
  shouldDisplayCloseButton?: boolean;
}

export const SimpleDialogBox = ({
  open = true,
  handleClose,
  title,
  description,
  cancelButton,
  actionButton,
  canExtendToFullWidth,
  maxWidth,
  shouldDisplayCloseButton,
}: Props) => (
  <Dialog open={open} onClose={handleClose} maxWidth={maxWidth} fullWidth={canExtendToFullWidth}>
    <DialogTitle>
      <Stack direction="row" justifyContent="space-between">
        {title}
        {shouldDisplayCloseButton && (
          <IconButtonAnimate>
            <Iconify
              icon={'ep:close-bold'}
              color="gray"
              sx={{ cursor: 'pointer' }}
              width={20}
              height={20}
              onClick={handleClose}
            />
          </IconButtonAnimate>
        )}
      </Stack>
    </DialogTitle>
    <DialogContent>
      {typeof description === 'string' ? (
        <DialogContentText>{description}</DialogContentText>
      ) : (
        description
      )}
    </DialogContent>
    <DialogActions>
      {cancelButton && <Button onClick={cancelButton.action}>{cancelButton.label}</Button>}
      <LoadingButton
        variant="contained"
        onClick={actionButton.action}
        autoFocus
        type="button"
        loading={actionButton.isLoading}
        color={actionButton.color}
        disabled={actionButton.disabled}
      >
        {actionButton.label}
      </LoadingButton>
    </DialogActions>
  </Dialog>
);
