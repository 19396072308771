import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';
import { MAX_INTEGER_VALUE } from 'utils/numberUtils';

export type NumberTextFieldProps = Omit<TextFieldProps, 'type'>;

export const NumberTextField = forwardRef<HTMLDivElement, NumberTextFieldProps>(
  ({ ...props }, ref) => (
    <TextField
      {...props}
      type="number"
      inputProps={{ ...props.inputProps, max: MAX_INTEGER_VALUE }}
      ref={ref}
    />
  )
);
