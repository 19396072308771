import { Button, Card } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FreemiumBannerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.info.lighter,
  color: theme.palette.info.darker,
  borderRadius: '8px',
  padding: '13px 25px 13px 20px',
}));

export const FreemiumButton = styled(Button)({
  whiteSpace: 'nowrap',
});

export const BannerContainer = styled(Card)({
  padding: '12px',
  display: 'flex',
  alignItems: 'top',
  boxShadow: 'none',
  borderRadius: '8px',
});

interface IconProps {
  fillColor: string;
}

export const StyledIcon = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fillColor',
})<IconProps>(({ fillColor }) => ({
  display: 'flex',
  paddingRight: '4px',
  path: {
    fill: fillColor,
  },
}));
