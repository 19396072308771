import { useIndexQuery } from 'api';
import { DateWithoutTime } from 'classes/DateWithoutTime';
import { useCurrentUser } from 'hooks/slices';
import { useIsAdminImpersonating } from 'hooks/slices/useCurrentUser';
import { useSelector } from 'react-redux';
import { selectUserToken } from 'redux/slices/auth';

export const useShouldAcceptCgu = (): { shouldAcceptCgu: boolean; isLoading: boolean } => {
  const isAdminImpersonating = useIsAdminImpersonating();
  const currentUser = useCurrentUser();
  const authToken = useSelector(selectUserToken);

  const globalSettingsQuery = useIndexQuery('core/global_settings', undefined, {
    enabled: !!currentUser,
    refetchOnWindowFocus: true,
  });

  const cguRequiredAt = globalSettingsQuery.data?.global_settings.cgu_required_at;
  const cguAcceptedAt = currentUser?.cgu_accepted_at;

  const isLoading = (!!authToken && !currentUser) || globalSettingsQuery.isLoading;
  const shouldAcceptCgu =
    !isAdminImpersonating &&
    currentUser?.kind !== 'admin' &&
    !!cguRequiredAt &&
    (!cguAcceptedAt ||
      DateWithoutTime.fromDateTimeString(cguAcceptedAt).isBefore(
        DateWithoutTime.fromString(cguRequiredAt)
      ));

  return {
    shouldAcceptCgu: shouldAcceptCgu,
    isLoading: isLoading,
  };
};
