import { PharmacyKind, ResellerWording } from 'types/models';
import { shouldNotHappen } from './baseUtils';

export const RESELLERS_KIND: PharmacyKind[] = [
  'pharmacy',
  'hospital_pharmacy',
  'parapharmacy',
  'medical_equipement_store',
  'sports_store',
  'organic_store',
  'wholesaler',
  'central_purchasing_office',
  'physiotherapist',
  'retirement_home',
  'e_commerce_website',
  'specialized_store',
  'gym',
  'beauty_sport_institute',
  'warehouse',
  'medical_profession_and_similar',
  'other',
];

export const resellerWordingMapping = (pharmacyKind: PharmacyKind): ResellerWording | undefined => {
  switch (pharmacyKind) {
    case 'pharmacy':
    case 'hospital_pharmacy':
    case 'parapharmacy':
      return 'pharmacy';
    case 'medical_equipement_store':
    case 'sports_store':
    case 'organic_store':
    case 'wholesaler':
    case 'central_purchasing_office':
    case 'physiotherapist':
    case 'retirement_home':
    case 'e_commerce_website':
    case 'specialized_store':
    case 'gym':
    case 'beauty_sport_institute':
    case 'warehouse':
    case 'medical_profession_and_similar':
    case 'other':
      return 'point_of_sale';
    default:
      shouldNotHappen(pharmacyKind, 'pharmacy kind not supported');
  }
};
