import { AxiosError } from 'axios';
import { UseMutationOptions, UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { GetOrderResponse, TransferOrderParams } from 'types/api/pharmacies/order';
import { ApiError } from 'types/models';
import { faksApiInstance as api } from './api';

export const useTransferOrderMutation = (
  options?: UseMutationOptions<GetOrderResponse, AxiosError<ApiError>, TransferOrderParams>
): UseMutationResult<GetOrderResponse, AxiosError<ApiError>, TransferOrderParams> => {
  const client = useQueryClient();

  return useMutation<GetOrderResponse, AxiosError<ApiError>, TransferOrderParams>(
    (params) => api.post(`pharmacies/orders/${params.id}/transfer`, params.fields),
    {
      ...options,
      onSuccess: (data, ...args) => {
        client.invalidateQueries(['pharmacies/orders', { id: data.order.id }]);
        client.setQueryData(['pharmacies/orders', { id: data.order.id }], data);
        options?.onSuccess?.(data, ...args);
      },
    }
  );
};
