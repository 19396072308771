import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function CssBaseline(theme: Theme) {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        label: { '& .MuiInputLabel-asterisk': { color: theme.palette.error.main } },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        hr: {
          border: 'none',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          margin: theme.spacing(1, 0),
        },
      },
    },
  };
}
