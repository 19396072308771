export enum GroupPaths {
  Dashboard = '/group/dashboard',
  NewsfeedPosts = '/group/newsfeed',
  NewsfeedPostEdit = '/group/newsfeed/:id/edit',
  NewsfeedPostNew = '/group/newsfeed/new',
  NewsfeedPost = '/group/newsfeed/:id',
  Pharmacies = '/group/pharmacies',
  PharmacyEdit = '/group/pharmacies/:id/edit',
  PharmacyLists = '/group/pharmacy_lists',
  PharmacyListNew = '/group/pharmacy_lists/new',
  PharmacyList = '/group/pharmacy_lists/:id',
  Claims = '/group/claims',
  Claim = '/group/claims/:id',
  GroupsLabs = '/group/groups_labs',
  GroupsLabsEdit = '/group/groups_labs/:id/edit',
  Trades = '/group/trades',
  Trade = '/group/trades/:id',
  TradeNew = '/group/trades/new',
  TradeEdit = '/group/trades/:id/edit',
  GroupEdit = '/group/:id/edit',
}
