import { useTheme as theme } from '@mui/material/styles';
import { FC } from 'react';
import { SvgIconProps } from 'types/icons';

const RoundedExclamation: FC<SvgIconProps> = ({ color = theme().palette.grey[500], size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
    <circle cx="8" cy="8" r="8" fill={color} />
    <path
      d="M8.76758 3.46875L8.62695 9.48633H7.41992L7.27344 3.46875H8.76758ZM7.23242 11.332C7.23242 11.1172 7.30273 10.9375 7.44336 10.793C7.58789 10.6445 7.78711 10.5703 8.04102 10.5703C8.29102 10.5703 8.48828 10.6445 8.63281 10.793C8.77734 10.9375 8.84961 11.1172 8.84961 11.332C8.84961 11.5391 8.77734 11.7168 8.63281 11.8652C8.48828 12.0098 8.29102 12.082 8.04102 12.082C7.78711 12.082 7.58789 12.0098 7.44336 11.8652C7.30273 11.7168 7.23242 11.5391 7.23242 11.332Z"
      fill="white"
    />
  </svg>
);

export default RoundedExclamation;
