/* eslint-disable @typescript-eslint/no-explicit-any */
import { lazy } from 'react';

export const lazyRetry = (componentImport: () => Promise<any>) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false'
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      const chunkFailedMessage = /Loading chunk [\d]+ failed/;

      // First chunk error, we reload for the user to have the latest version of the app
      if (!pageHasAlreadyBeenForceRefreshed && chunkFailedMessage.test(error.message)) {
        window.localStorage.setItem('page-has-been-force-refreshed', 'true');
        return window.location.reload();
      } else {
        throw error;
      }
    }
  });
