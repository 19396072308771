import { TextFieldProps } from '@mui/material';
import Select from 'components/Select';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  name: string;
  options: { value: string; title: string }[];
} & TextFieldProps;

export const RHFSelect = ({ name, options, ...other }: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Select
          {...field}
          error={!!error}
          helperText={error?.message}
          options={options}
          {...other}
        />
      )}
    />
  );
};
