import { AxiosError } from 'axios';
import { useOnErrorNavigation } from 'hooks/useOnErrorNavigation';
import { serialize } from 'object-to-formdata';
import {
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import {
  CreateClaimParams,
  CreateClaimResponse,
  DeleteClaimParams,
  GetClaimParams,
  GetClaimResponse,
  GetClaimsParams,
  GetClaimsResponse,
  UpdateClaimParams,
  UpdateClaimResponse,
} from 'types/api/claim';
import { SuccessResponse } from 'types/api/common';
import { ApiError, faksApiInstance as api } from './api';
import { withSimplifiedRefetch } from './hooks';

export const useGetClaimsInfiniteQuery = (
  params?: Omit<GetClaimsParams, 'page'>,
  options?: UseInfiniteQueryOptions<
    GetClaimsResponse,
    AxiosError<ApiError>,
    GetClaimsResponse,
    GetClaimsResponse,
    Array<string | Omit<GetClaimsParams, 'page'> | undefined>
  >
) => {
  const client = useQueryClient();
  const query = useInfiniteQuery(
    ['invoices', params],
    ({ pageParam = 1 }) =>
      api.get<GetClaimsResponse>('invoices', {
        params: { page: pageParam, ...params },
      }),
    {
      getNextPageParam: ({ meta }) =>
        meta.current_page >= meta.total_pages ? undefined : meta.current_page + 1,
      ...options,
    }
  );
  return withSimplifiedRefetch(query, ['invoices', params], client);
};

export const useGetClaimQuery = (
  params?: GetClaimParams,
  options?: UseQueryOptions<GetClaimResponse, AxiosError<ApiError>>
) => {
  const { handleErrorNavigation } = useOnErrorNavigation();
  return useQuery<GetClaimResponse, AxiosError<ApiError>>(
    ['invoices', params],
    () => api.get<GetClaimResponse>(`invoices/${params?.id}`),
    {
      enabled: !!params?.id,
      onError: (error) => handleErrorNavigation(error.response?.status),
      ...options,
    }
  );
};

export const useUpdateClaimMutation = (
  options?: UseMutationOptions<UpdateClaimResponse, AxiosError<ApiError>, UpdateClaimParams>
): UseMutationResult<UpdateClaimResponse, AxiosError<ApiError>, UpdateClaimParams> => {
  const client = useQueryClient();
  return useMutation<UpdateClaimResponse, AxiosError<ApiError>, UpdateClaimParams>(
    (params) => api.patch<UpdateClaimResponse>(`invoices/${params.id}`, params.invoice),
    {
      ...options,
      onSuccess: (data) => {
        client.invalidateQueries('invoices');
        client.setQueryData<GetClaimResponse>(`invoices/${data.invoice.id}`, data);
      },
    }
  );
};

export const useDeleteClaimMutation = (
  options?: UseMutationOptions<SuccessResponse, AxiosError<ApiError>, DeleteClaimParams>
): UseMutationResult<SuccessResponse, AxiosError<ApiError>, DeleteClaimParams> =>
  useMutation<SuccessResponse, AxiosError<ApiError>, DeleteClaimParams>(
    (params) => api.delete<SuccessResponse>(`invoices/${params.id}`),
    options
  );

export const useCreateClaimMutation = (
  options?: UseMutationOptions<CreateClaimResponse, AxiosError<ApiError>, CreateClaimParams>
): UseMutationResult<CreateClaimResponse, AxiosError<ApiError>, CreateClaimParams> =>
  useMutation<CreateClaimResponse, AxiosError<ApiError>, CreateClaimParams>(
    (params) => api.post<CreateClaimResponse>('invoices', serialize(params)),
    options
  );
