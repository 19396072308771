import { AxiosError } from 'axios';
import { UseMutationOptions, UseMutationResult, useMutation } from 'react-query';
import { PharmacyResponse, UpdatePharmacyParams } from 'types/api/pharmacies/pharmacy';
import { ApiError, faksApiInstance as api } from './api';

export const useUpdatePharmacyMutation = (
  options?: UseMutationOptions<PharmacyResponse, AxiosError<ApiError>, UpdatePharmacyParams>
): UseMutationResult<PharmacyResponse, AxiosError<ApiError>, UpdatePharmacyParams> =>
  useMutation<PharmacyResponse, AxiosError<ApiError>, UpdatePharmacyParams>(
    (params) => api.patch<PharmacyResponse>(`pharmacies/${params.pharmacy.id}`, params),
    options
  );
