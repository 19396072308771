import { UseInfiniteQueryResult } from 'react-query';

export const scrollUtils = {
  onScrollToBottom: (event: React.SyntheticEvent, handleOnBottomReached: () => void) => {
    const node = event.currentTarget;
    const isBottomReached = node.scrollTop + node.clientHeight >= 0.9 * node.scrollHeight;

    if (isBottomReached) handleOnBottomReached();
  },
  triggerFetchNextPageOnScrollToBottom: <TData>(query: UseInfiniteQueryResult<TData, unknown>) => {
    const { hasNextPage, isFetchingNextPage, fetchNextPage } = query;

    const handleBottomIsReachedOnScroll = () => {
      if (hasNextPage && !isFetchingNextPage) fetchNextPage();
    };

    return (event: React.SyntheticEvent) =>
      scrollUtils.onScrollToBottom(event, handleBottomIsReachedOnScroll);
  },
};
