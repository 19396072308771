import { compact } from 'lodash';
import { User } from 'types/models';

export const userUtils = {
  getFullName: (user: Pick<User, 'first_name' | 'last_name'> | null): string =>
    user ? compact([user.first_name, user.last_name]).join(' ') : '',

  getFullNameOrEmail: (user: Pick<User, 'first_name' | 'last_name' | 'email'> | null): string => {
    if (user?.first_name && user?.last_name) {
      return userUtils.getFullName(user);
    } else {
      return user?.email ? user.email : '';
    }
  },

  getUsersOptions: (users?: User[], isEmailRequired?: boolean) =>
    users
      ? users.map((user) => ({
          value: user.id,
          title: userUtils.getFullName(user) + `${isEmailRequired ? ` - ${user.email}` : ''}`,
        }))
      : [],
};
