import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Link(theme: Theme) {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          '&:hover': {
            cursor: 'pointer',
          },
          width: 'fit-content',
        },
      },
      defaultProps: {
        underline: 'hover',
      },
    },
  };
}
