import { styled } from '@mui/material';

export const BadgeInternal = styled('div')({
  borderRadius: '7px',
  padding: '3px 9px',
  color: 'white',
  fontWeight: '700',
  fontSize: '12px',
  textAlign: 'center',
  display: 'inline',
  whiteSpace: 'nowrap',
});

type Props = {
  color?: string;
  text?: string;
  sx?: React.CSSProperties;
};

export const Badge = ({ color, text, sx }: Props) => (
  <BadgeInternal style={{ backgroundColor: color, whiteSpace: 'nowrap' }} sx={sx}>
    {text}
  </BadgeInternal>
);
