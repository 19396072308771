import {
  AdminResourceUserPicker,
  AdminResourceUserPickerProps,
} from 'components/admin/pickers/AdminResourceUserPicker';
import { useTranslation } from 'locales/i18n';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  name: string;
} & Omit<AdminResourceUserPickerProps, 'onChange'>;

export const RHFAdminResourceUserPicker = ({ name, ...props }: Props) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <AdminResourceUserPicker
            {...props}
            onChange={field.onChange}
            selectedUserId={field.value}
            inputParams={{
              helperText: error?.message,
              error: !!error,
              placeholder: t('shared.user'),
              ...props.inputParams,
            }}
          />
        );
      }}
    />
  );
};
