import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded';
import {
  GetPanelistConnectionStatusResponse,
  PanelistConnectionStatus,
} from 'types/api/pharmacies/panelist_connection';
import { ConnectionEstablishedBanner } from './banners/ConnectionEstablishedBanner';
import { ConnectionEstablishingBanner } from './banners/ConnectionEstablishingBanner';
import { ConnectionNotEstablishedBanner } from './banners/ConnectionNotEstablishedBanner';

type IconFunction = (fontSize: 'small' | 'medium') => JSX.Element;
type BannerFunction = (panelistConnection: GetPanelistConnectionStatusResponse) => JSX.Element;

type PanelistConnectionStatusIconProps = {
  fontSize: 'small' | 'medium';
  status: PanelistConnectionStatus;
};

type PanelistConnectionStatusBannerProps = {
  panelistConnection: GetPanelistConnectionStatusResponse;
};

const panelistStatusComponents: Record<
  PanelistConnectionStatus,
  { icon: IconFunction; banner?: BannerFunction } | null
> = {
  established: {
    icon: (fontSize: 'small' | 'medium') => (
      <CheckCircleIcon fontSize={fontSize} sx={{ marginRight: 1 }} color="success" />
    ),
    banner: () => <ConnectionEstablishedBanner />,
  },
  establishing: {
    icon: (fontSize: 'small' | 'medium') => (
      <WatchLaterRoundedIcon fontSize={fontSize} sx={{ marginRight: 1 }} color="warning" />
    ),
    banner: (panelistConnection: GetPanelistConnectionStatusResponse) => (
      <ConnectionEstablishingBanner panelist={panelistConnection.pharmacy_panelist?.panelist} />
    ),
  },
  allowed: {
    icon: (fontSize: 'small' | 'medium') => (
      <InfoIcon fontSize={fontSize} sx={{ marginRight: 1 }} color="warning" />
    ),
  },
  failed: {
    icon: (fontSize: 'small' | 'medium') => (
      <ErrorIcon fontSize={fontSize} sx={{ marginRight: 1 }} color="error" />
    ),
    banner: (panelistConnection: GetPanelistConnectionStatusResponse) => (
      <ConnectionNotEstablishedBanner
        panelist={panelistConnection.pharmacy_panelist?.panelist}
        lastSelloutRequest={panelistConnection.last_sellout_request}
      />
    ),
  },
  not_allowed: null,
};

export const PanelistConnectionStatusIcon = ({
  status,
  fontSize,
}: PanelistConnectionStatusIconProps) => {
  const icon = panelistStatusComponents[status]?.icon;
  return icon && icon(fontSize);
};

export const PanelistConnectionStatusBanner = ({
  panelistConnection,
}: PanelistConnectionStatusBannerProps) => {
  const banner = panelistStatusComponents[panelistConnection.status]?.banner;
  return banner && banner(panelistConnection);
};
