import { useCurrentGroup } from '../useCurrentGroup';

export const useCurrentGroupOrThrow = () => {
  const currentGroup = useCurrentGroup();
  if (!currentGroup) {
    throw new Error('Current group is not defined');
  }

  return currentGroup;
};
