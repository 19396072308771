import { Panelist } from 'types/models/pharmacy_lgo_settings';

export const _5_MINUTES_IN_MS = 1000 * 60 * 5;

export const PANELIST_SUPPORT_PHONE_NUMBER: Record<Panelist, string> = {
  santestat: '01 49 09 40 08',
  ospharm: '02 40 53 63 44',
  offisante: '01 84 17 28 11',
};

export const _1_MB_IN_BYTES = 1_000_000;
