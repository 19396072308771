import { useCurrentPharmacy } from '../useCurrentPharmacy';

export const useCurrentPharmacyOrThrow = () => {
  const currentPharmacy = useCurrentPharmacy();
  if (!currentPharmacy) {
    throw new Error('Current pharmacy is not defined');
  }

  return currentPharmacy;
};
