import { useCurrentRole } from 'hooks/slices';
import { useSelector } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';
import { selectUserToken } from 'redux/slices/auth';
import { useIsCurrentUserAdmin, useIsUserOnboarder } from '../hooks/slices/useCurrentUser';
import { SharedPaths } from './paths/SharedPaths';
import { canAccessLabPages, canAccessPharmacyPages } from './routeUtils';

export const RootNavigate = () => {
  const authToken = useSelector(selectUserToken);
  const isUserAdmin = useIsCurrentUserAdmin();
  const isUserOnboarder = useIsUserOnboarder();
  const role = useCurrentRole();
  const [searchParams] = useSearchParams();
  const destinationPath = searchParams.get('destination_path');

  if (authToken === null)
    return (
      <Navigate
        to={`/login?redirection_url=${encodeURIComponent(destinationPath || '')}`}
        replace
      />
    );

  if (isUserAdmin) return <Navigate to="/admin" replace />;
  if (isUserOnboarder) return <Navigate to="/onboarder" replace />;

  if (!role) return <Navigate to="/onboarding" replace />;

  if (role.resource_type === 'Group') return <Navigate to="/group" replace />;

  if (role.resource_type === 'Lab') {
    if (canAccessLabPages(role)) return <Navigate to="/lab" replace />;
    else return <Navigate to={SharedPaths.Unauthorized} replace />;
  }

  if (role.resource_type === 'Pharmacy') {
    if (canAccessPharmacyPages(role)) return <Navigate to="/pharmacy" replace />;
    else return <Navigate to="/onboarding" replace />;
  }
};

export default RootNavigate;
