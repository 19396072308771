import { styled } from '@mui/material';

export const ModalContainer = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  border: '1px solid transparent',
  borderRadius: '0.5rem',
  outline: '0',
  padding: '20px',
  maxHeight: '95vh',
  overflowY: 'scroll',
});
