import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, MenuItem, TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';
import { FilterItem } from 'types/filter';

export type SelectProps<T extends number | string> = {
  options: FilterItem<T>[];
  onClear?: () => void;
} & TextFieldProps;

const Select = forwardRef<HTMLDivElement, SelectProps<string | number>>(
  ({ label, options, onClear, ...other }, ref) => (
    <TextField
      select
      fullWidth
      label={label}
      SelectProps={{
        MenuProps: {
          sx: { '& .MuiPaper-root': { maxHeight: 260 } },
        },
        ...(onClear && {
          endAdornment: (
            <IconButton
              onClick={onClear}
              size="small"
              sx={{ marginRight: '7px' }}
              disabled={other.disabled}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }),
      }}
      InputLabelProps={{
        className: 'faks-select-label',
      }}
      {...other}
      value={other.value || ''}
      ref={ref}
    >
      {options.map((option, index) => (
        <MenuItem
          key={index}
          value={option.value}
          sx={{
            mx: 1,
            my: 0.5,
            borderRadius: 0.75,
            typography: 'body2',
          }}
          disabled={option.isDisabled}
        >
          {option.title}
        </MenuItem>
      ))}
    </TextField>
  )
);

export default Select;
