import { useTheme as theme } from '@mui/material/styles';
import { FC } from 'react';
import { SvgIconProps } from 'types/icons';

const Alert: FC<SvgIconProps> = ({ color = theme().palette.grey[500], size = 24 }) => (
  <svg width={size} height={size} fill="none" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.56 16.3 14.89 3.58a3.43 3.43 0 0 0-5.78 0L1.44 16.3a3 3 0 0 0-.05 3A3.37 3.37 0 0 0 4.33 21h15.34a3.37 3.37 0 0 0 2.94-1.66 3 3 0 0 0-.05-3.04ZM12 17a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0-3a1 1 0 0 0 1-1V9a1 1 0 1 0-2 0v4a1 1 0 0 0 1 1Z"
      fill={color}
    />
  </svg>
);

export default Alert;
