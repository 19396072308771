import Select, { SelectProps } from 'components/Select';
import { Ref, forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FilterItem } from 'types/filter';

type Props<T extends number | string> = {
  name: string;
  options: FilterItem<T>[];
} & SelectProps<T>;

export const SelectField = forwardRef(
  <T extends number | string>({ name, options, ...other }: Props<T>, ref?: Ref<HTMLDivElement>) => {
    const { control } = useFormContext();

    return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Select
            {...field}
            fullWidth
            name={name}
            options={options}
            error={!!error}
            helperText={error?.message}
            {...other}
            ref={ref}
          />
        )}
      />
    );
  }
);
