import { useTheme as theme } from '@mui/material/styles';
import Iconify from 'components/Iconify';
import { FC } from 'react';
import { SvgIconProps } from 'types/icons';

const Info: FC<SvgIconProps> = ({ color = theme().palette.grey[500], size = 30 }) => (
  <Iconify
    icon={'material-symbols:info-rounded'}
    sx={{
      width: size,
      height: size,
      color: color,
    }}
  />
);

export default Info;
