import { QueryClient } from 'react-query';

export * from './api';
export * from './authHooks';
export * from './claimHooks';
export * from './genericHooks';
export * from './hooks';
export * from './labHooks';
export * from './orderHooks';
export * from './pharmacyHooks';
export * from './topicHooks';
export * from './userHooks';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});
