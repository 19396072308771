import { useTranslation } from 'locales/i18n';
import { BadgeInternal } from './Badge';

export const NewBadge = () => {
  const { t } = useTranslation();

  return (
    <BadgeInternal style={{ backgroundColor: 'lightgreen', color: 'green' }}>
      {t(`shared.new`)}
    </BadgeInternal>
  );
};
