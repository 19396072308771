import { RoleKind } from 'types/models';

export const anonymizeEmail = (email: string): string => {
  const [name, domain] = email.split('@');

  return `${name.replace(/^(..).*(..)$/, '$1****$2')}@${domain}`;
};

export enum PharmacyVerificationState {
  email = 'email',
  phoneBack = 'phoneBack',
}

export const getPharmacyVerificationState = (
  isPharmacyVerified: boolean,
  roleKind?: RoleKind
): PharmacyVerificationState | undefined => {
  if ('no_access' === roleKind || roleKind === 'pharmacy_owner_unverified_email')
    return PharmacyVerificationState.email;
  if (
    roleKind === 'pharmacy_owner_unverified_phone' ||
    (!isPharmacyVerified && (roleKind === 'pharmacy_owner' || roleKind === 'pharmacist'))
  )
    return PharmacyVerificationState.phoneBack;
  return undefined;
};
