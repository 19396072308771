import { Backdrop, Divider, Modal, ModalProps, Stack, Typography } from '@mui/material';
import Iconify from 'components/Iconify';
import { FC, ReactNode } from 'react';
import { ModalContainer } from './styles';

export interface Props extends Omit<ModalProps, 'open' | 'children'> {
  width?: string;
  open?: boolean;
  shouldDisplayDivider?: boolean;
  children: ReactNode;
}

export const BasicModal: FC<Props> = ({
  children,
  title,
  onClose,
  open = true,
  width = '600px',
  shouldDisplayDivider = true,
  ...other
}) => (
  <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    closeAfterTransition
    slots={{ backdrop: Backdrop }}
    slotProps={{
      backdrop: {
        timeout: 500,
        sx: { background: 'none', backgroundColor: 'primary.light', opacity: '0.2 !important' },
      },
    }}
    {...other}
    onClose={onClose}
    open={open}
  >
    <ModalContainer
      sx={{ boxShadow: 24, '&::-webkit-scrollbar': { display: 'none' }, width: width }}
    >
      <Stack direction="row-reverse" justifyContent={onClose ? 'space-between' : 'center'}>
        {onClose && (
          <Iconify
            icon={'ep:close-bold'}
            color="gray"
            sx={{ cursor: 'pointer' }}
            width={20}
            height={20}
            onClick={(e) => onClose(e, 'backdropClick')}
          />
        )}
        {title && (
          <Typography variant="h5" color="GrayText" mr={2}>
            {title}
          </Typography>
        )}
      </Stack>
      {shouldDisplayDivider && <Divider sx={{ mt: 2, mb: 3 }} />}
      {children}
    </ModalContainer>
  </Modal>
);
