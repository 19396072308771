import { useCurrentRole } from '../useCurrentRole';

export const useCurrentRoleOrThrow = () => {
  const currentRole = useCurrentRole();
  if (!currentRole) {
    throw new Error('Current role is not defined');
  }

  return currentRole;
};
