export enum LoginPaths {
  PhoneNumber = '/login/phone_number',
  Code = '/login/code',
  Email = '/login/email',
  Invitation = '/login/invitation',
  FromToken = '/login/from_token',
  FromIdentityProvider = '/login/from_identity_provider',
  PasswordReset = '/login/password/reset',
  PasswordEdit = '/login/password/edit',
}
