import { useGetUserQuery } from 'api';
import { includes } from 'lodash';
import { useSelector } from 'react-redux';
import {
  selectCurrentRoleId,
  selectCurrentUserId,
  selectUserToken,
  setCurrentRoleId,
  setCurrentUserId,
  setTrueUserId,
} from 'redux/slices/auth';
import { dispatch } from 'redux/store';

export const useCurrentUserAndValidateRoles = () => {
  const authToken = useSelector(selectUserToken);
  const currentUserId = useSelector(selectCurrentUserId);
  const currentRoleId = useSelector(selectCurrentRoleId);

  const { data } = useGetUserQuery({
    enabled: !!authToken,
    onSuccess: (data) => {
      dispatch(setTrueUserId(data.meta.true_user_id));
      if (!currentUserId) {
        dispatch(setCurrentUserId(data.user.id));
      }

      const rolesWithAccess = data.user.roles?.filter(
        (role) => !includes(['lab_sales_rep_no_access', 'no_access'], role.kind)
      );

      const currentRole = data.user.roles?.find((role) => role.id === currentRoleId);

      const roleToUse = currentRole || rolesWithAccess?.[0] || data.user.roles?.[0] || null;

      // Important to avoid unwanted false positive because undefined !== null
      const realRoleToUseId: number | null = roleToUse ? roleToUse.id : null;

      if (realRoleToUseId !== currentRoleId) {
        dispatch(setCurrentRoleId(realRoleToUseId));
      }
    },
    refetchOnWindowFocus: true,
  });

  return data ? { currentUser: data.user, trueUserId: data.meta.true_user_id } : null;
};
