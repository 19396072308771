import { Box, Stack, Typography } from '@mui/material';
import Image from 'components/Image';
import { FC, ReactNode } from 'react';
import { SubtitleText } from './styles';

type Props = {
  title: string;
  subtitle: string | ReactNode;
  header?: ReactNode;
  children: ReactNode;
};

export const LoginLayout: FC<Props> = ({ title, subtitle, children, header }) => (
  <>
    {header ? (
      header
    ) : (
      <Box display="flex" justifyContent="center" mb={3}>
        <Image alt="Faks_logo" src="/assets/faks_logo.svg" sx={{ width: 122 }} />
      </Box>
    )}
    <Stack direction="row" alignItems="center">
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h3" textAlign="center">
          {title}
        </Typography>
        <SubtitleText mt={2}>{subtitle}</SubtitleText>
      </Box>
    </Stack>
    <Box mt={5}>{children}</Box>
  </>
);
