import { useTheme as theme } from '@mui/material/styles';
import { FC } from 'react';
import { SvgIconProps } from 'types/icons';

const Home: FC<SvgIconProps> = ({ color = theme().palette.grey[500], size = 24 }) => (
  <svg width={size} height={size} fill="none" viewBox="0 0 24 22">
    <path
      d="M23.032 10.822 12.574.372a.811.811 0 0 0-1.148 0L.968 10.821a1.626 1.626 0 0 0 1.148 2.775h1.101v7.458c0 .449.364.812.813.812h6.345v-5.688h2.844v5.688h6.751c.45 0 .813-.363.813-.813v-7.457h1.102a1.627 1.627 0 0 0 1.148-2.775Z"
      fill={color}
    />
  </svg>
);

export default Home;
