import { NavIcon } from 'components/nav-section/NavIcon';
import { NavSection } from 'components/nav-section/NavSection';
import { NavListProps } from 'components/nav-section/type';
import { useCurrentGroupOrThrow, useCurrentRole } from 'hooks/slices';
import { useTranslation } from 'locales/i18n';
import { generatePath } from 'react-router';
import { GroupPaths, SharedPaths } from 'routes/paths';

export function GroupNavSection() {
  const { t } = useTranslation();

  const currentRole = useCurrentRole();
  const isResourceAdmin = currentRole && currentRole.kind === 'group_admin';
  const { is_newsfeed_enabled: isNewsfeedEnabled, id: groupId } = useCurrentGroupOrThrow();

  const navItems: NavListProps[] = [
    {
      title: t('navbar.dashboard'),
      path: GroupPaths.Dashboard,
      icon: <NavIcon name="ic_mail" />,
    },
    ...(isNewsfeedEnabled
      ? [
          {
            title: t('shared.newsfeed'),
            path: GroupPaths.NewsfeedPosts,
            icon: <NavIcon name="ic_news" />,
          },
        ]
      : []),
    {
      title: t('shared.pharmacies'),
      path: GroupPaths.Pharmacies,
      icon: <NavIcon name="ic_pharma" />,
    },
    {
      title: t('shared.invoices'),
      path: GroupPaths.Claims,
      icon: <NavIcon name="ic_invoice" />,
    },
    { title: t('shared.labs'), path: GroupPaths.GroupsLabs, icon: <NavIcon name="ic_lab" /> },
    { title: t('shared.trades'), path: GroupPaths.Trades, icon: <NavIcon name="ic_ecommerce" /> },
    ...(isResourceAdmin
      ? [
          {
            title: t('shared.settings'),
            path: generatePath(GroupPaths.GroupEdit, { id: String(groupId) }),
            icon: <NavIcon name="ic_settings" />,
          },
        ]
      : []),
    ...[
      {
        title: 'Aide',
        icon: <NavIcon name="ic_question" />,
        path: SharedPaths.IntercomFAQ,
      },
    ],
  ];

  return <NavSection navItems={navItems} />;
}
