import { isEqual } from 'lodash';
import { ErrorDescription } from 'types/handled_error';
import { adminHandledErrors } from './adminHandledErrors';
import { groupsHandledErrors } from './groupsHandledErrors';
import { labsHandledErrors } from './labsHandledErrors';
import { pharmaciesHandledErrors } from './pharmaciesHandledErrors';

const sharedHandledErrors: ErrorDescription[] = [
  {
    endpoint: 'initiate_auth_with_phone',
    method: 'post',
    responseStatus: 401,
    error: { phone_number: 'Not found' },
  },
  {
    endpoint: 'authenticate_with_phone_and_code',
    method: 'post',
    responseStatus: 401,
    error: { code_verification: 'invalid code' },
  },
  {
    endpoint: 'authenticate',
    method: 'post',
    responseStatus: 401,
    error: { user_authentication: 'invalid credentials' },
  },
  {
    endpoint: 'pharmacies',
    method: 'patch',
    responseStatus: 500,
    error: 'phone_already_taken',
  },
  {
    endpoint: 'pharmacies',
    method: 'post',
    responseStatus: 400,
    error: 'invalid_zip_code',
  },
  {
    endpoint: 'pharmacies',
    method: 'patch',
    responseStatus: 400,
    error: 'invalid_zip_code',
  },
  {
    endpoint: 'user/ask_for_invitation',
    method: 'post',
    responseStatus: 500,
    error: 'user_already_exist',
  },

  {
    endpoint: 'roles',
    method: 'post',
    responseStatus: 409,
    error: 'role_already_exists',
  },
  {
    endpoint: 'roles',
    method: 'post',
    responseStatus: 409,
    error: 'multiple_users_exist',
  },
  {
    endpoint: 'roles',
    method: 'delete',
    responseStatus: 404,
  },
  {
    endpoint: 'ingestions_tasks',
    method: 'post',
    responseStatus: 422,
    error: 'invalid_data',
  },
  {
    endpoint: 'ingestions_tasks',
    method: 'post',
    responseStatus: 422,
    error: 'invalid_option',
  },
  {
    endpoint: 'user/update_password_after_reset',
    method: 'patch',
    responseStatus: 401,
    error: 'invalid_token',
  },
  {
    endpoint: 'user/update_password',
    method: 'patch',
    responseStatus: 401,
    error: 'wrong_password',
  },
  {
    endpoint: 'roles',
    method: 'post',
    responseStatus: 409,
    error: 'admins_cant_have_roles',
  },
  {
    endpoint: 'user/reset_password',
    method: 'post',
    responseStatus: 400,
    error: 'idp_bound_domain',
  },
  {
    endpoint: 'user/update_password',
    method: 'post',
    responseStatus: 400,
    error: 'idp_bound_domain',
  },
];

const allHandledErrorsList = [
  ...sharedHandledErrors,
  ...pharmaciesHandledErrors,
  ...groupsHandledErrors,
  ...labsHandledErrors,
  ...adminHandledErrors,
];

export const isHandled = (error: ErrorDescription): boolean =>
  !!allHandledErrorsList.find(
    (handledError: ErrorDescription) =>
      ((typeof handledError.endpoint === 'string' && handledError.endpoint === error.endpoint) ||
        (handledError.endpoint instanceof RegExp &&
          handledError.endpoint.test(error.endpoint as string))) &&
      handledError.method === error.method &&
      handledError.responseStatus === error.responseStatus &&
      isEqual(handledError.error, error.error)
  );
