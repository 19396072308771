import ErrorIcon from '@mui/icons-material/Error';
import { Box, Stack } from '@mui/system';
import { Banner } from 'components/banner/banner';
import { t } from 'locales/i18n';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { GetPanelistConnectionStatusResponse } from 'types/api/pharmacies/panelist_connection';
import { Panelist } from 'types/models/pharmacy_lgo_settings';
import { PanelistConnectionSteps } from '../PanelistConnectionSteps';

type Props = {
  panelist: Panelist | null | undefined;
  lastSelloutRequest: GetPanelistConnectionStatusResponse['last_sellout_request'];
};

export const ConnectionNotEstablishedBanner = ({ panelist, lastSelloutRequest }: Props) =>
  panelist &&
  lastSelloutRequest?.error_code && (
    <Banner
      icon={<ErrorIcon fontSize="medium" sx={{ marginRight: 1 }} />}
      title={
        <Stack gap={2}>
          <Box>
            <Trans
              i18nKey={'pharmacyLgoConnection.connectionNotEstablished'}
              components={[<strong />]}
              values={{
                selloutRequestDate: moment(lastSelloutRequest.last_updated_at).format('LL'),
                tradeTitle: lastSelloutRequest.trade_name,
                errorDetails: t(
                  `pharmacyLgoConnection.connectionErrorDetails.${lastSelloutRequest.error_code}`,
                  { panelist: panelist }
                ),
              }}
            />
          </Box>
          <Box>
            <PanelistConnectionSteps panelist={panelist} />
          </Box>
        </Stack>
      }
      color="error"
      sx={{ mt: 2 }}
    />
  );
