import { includes } from 'lodash';
import { Pharmacy, PharmacyRole, Role, RoleWithResource, User } from 'types/models';
import {
  isGroupRole,
  isLabRole,
  isPharmacyRole,
  isSalesRep,
  isSalesRepNoAccess,
} from 'utils/rolesUtils';

export const canAccessGroupPages = (currentRole: Role | null) =>
  !!currentRole && isGroupRole(currentRole);

export const canAccessLabPages = (currentRole: Role | null) =>
  !!currentRole &&
  isLabRole(currentRole) &&
  !isSalesRep(currentRole.kind) &&
  !isSalesRepNoAccess(currentRole.kind);

export const canAccessPharmacyPages = (currentRole: RoleWithResource | null) =>
  !!currentRole && isPharmacyRole(currentRole) && isPharmacyOnboardingComplete(currentRole);

export const canAccessOnboardingPages = (currentRole: RoleWithResource | null) =>
  !currentRole ||
  (currentRole.resource_type === 'Pharmacy' && !isPharmacyOnboardingComplete(currentRole));

export const canAccessOnboarderPages = (currentUser: User | null) =>
  includes(['admin', 'onboarding'], currentUser?.kind);

export const canAccessAdminPages = (currentUser: User | null) => currentUser?.kind === 'admin';

const unverifiedPharmacyRoles = [
  'pharmacy_owner_unverified_phone',
  'pharmacy_owner_unverified_email',
  'no_access',
];

export const isPharmacyOnboardingComplete = (
  userRole: PharmacyRole & { resource: Pharmacy }
): boolean => userRole.resource.verified && !includes(unverifiedPharmacyRoles, userRole.kind);
