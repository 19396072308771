import { useNavigate } from 'react-router-dom';
import { SharedPaths } from 'routes/paths';

export const useOnErrorNavigation = () => {
  const navigate = useNavigate();

  const handleErrorNavigation = (status?: number) => {
    if (status === 403) navigate(SharedPaths.Unauthorized);
    if (status === 404) navigate(SharedPaths.NotFound);
  };
  return { handleErrorNavigation };
};
