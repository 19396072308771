import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js';

export const validatePhoneNumber = (phoneNumber: string | null | undefined) =>
  !!phoneNumber && isValidPhoneNumber(phoneNumber);

const domTom = ['gp', 'mq', 'gf', 're', 'yt', 'pm', 'bl', 'mf', 'nc', 'pf', 'wf', 'tf'];

export const phoneNumberFormat = {
  gp: '... .. .. ..',
  mq: '... .. .. ..',
  gf: '... .. .. ..',
  re: '... .. .. ..',
  yt: '... .. .. ..',
  pm: '.. .. ..',
  bl: '... .. .. ..',
  mf: '... .. .. ..',
  nc: '.. .. ..', // normally it's a dot between pair numbers
  pf: '.. .. .. ..',
  wf: '.. .. ..',
  mc: '.. .. .. ..',
  lu: '.. .. .. ..',
  ad: '... ...',
};

export const supportedPhoneCountries = ['fr', 'ch', 'mc', 'be', 'lu', 'ad'].concat(domTom);

export const formatPhoneNumberWithCountryCode = (phoneNumber: string) => {
  return parsePhoneNumber(phoneNumber)?.formatInternational() || phoneNumber;
};
