import { ErrorDescription } from 'types/handled_error';

export const pharmaciesHandledErrors: ErrorDescription[] = [
  {
    endpoint: 'pharmacies',
    method: 'patch',
    responseStatus: 400,
    error: 'cip_already_taken',
  },
  {
    endpoint: 'pharmacies/orders',
    method: 'patch',
    responseStatus: 409,
    error: 'invalid_transition',
  },
  {
    endpoint: 'pharmacies/labs_pharmacies',
    method: 'patch',
    responseStatus: 409,
    error: 'phone_already_taken',
  },
  {
    endpoint: /expired_products_requests\/[\d]*\/generate_destruction_certificate/,
    method: 'patch',
    responseStatus: 409,
    error: 'procedure_generation_failed',
  },
  {
    endpoint: 'pharmacies/orders',
    method: 'post',
    responseStatus: 422,
    error: 'receiver_cannot_be_admin',
  },
  {
    endpoint: 'pharmacies/trades/_request_print_poster_url',
    method: 'post',
    responseStatus: 500,
    error: 'boost_failed',
  },
  {
    endpoint: /pharmacies\/labs_pharmacies\/[\d]*\/_update_sales_rep_email_address/,
    method: 'post',
    responseStatus: 409,
    error: 'email_already_taken',
  },
];
