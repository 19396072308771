import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import authReducer from './slices/auth';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['auth'],
};

const rootReducer = combineReducers({
  auth: authReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export { rootPersistConfig, rootReducer };
