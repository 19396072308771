import { Box, Button, Stack, Typography, styled, useTheme } from '@mui/material';
import { useGetUserQuery } from 'api';
import { useIsAdminImpersonating } from 'hooks/slices/useCurrentUser';
import { useTranslation } from 'locales/i18n';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearImpersonatedUser, selectUserToken } from 'redux/slices/auth';
import { useDispatch } from 'redux/store';
import { userUtils } from 'utils/userUtils';

const BannerContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.warning.lighter,
  color: theme.palette.warning.main,
  borderRadius: '8px',
  padding: theme.spacing(2),
}));

export const OptImpersonationBanner = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authToken = useSelector(selectUserToken);

  const theme = useTheme();

  const getUserQuery = useGetUserQuery({ enabled: !!authToken });
  const currentUser = getUserQuery.data?.user || null;
  const trueUserName = getUserQuery.data?.meta?.true_user_name;

  const isAdminImpersonating = useIsAdminImpersonating();

  const handleStopImpersonating = () => {
    dispatch(clearImpersonatedUser());
    navigate('/');
  };

  if (!isAdminImpersonating) return <></>;

  return (
    <BannerContainer sx={{ m: 2 }}>
      <Box>
        <Box sx={{ mb: 1 }}>
          <Typography sx={{ fontSize: 14 }}>
            <Trans
              i18nKey={'admin.isImpersonating'}
              components={[<strong />]}
              values={{
                trueUser: trueUserName,
                currentUser: userUtils.getFullName(currentUser),
              }}
            />
          </Typography>
        </Box>
        <Button
          color="warning"
          variant="contained"
          fullWidth
          onClick={handleStopImpersonating}
          sx={{ color: theme.palette.warning.lighter }}
        >
          {t('admin.stopImpersonating')}
        </Button>
      </Box>
    </BannerContainer>
  );
};
