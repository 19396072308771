import { useTheme as theme } from '@mui/material/styles';
import { FC } from 'react';
import { SvgIconProps } from 'types/icons';

const Marker: FC<SvgIconProps> = ({ color = theme().palette.grey[500], size = 24 }) => (
  <svg width={size} height={size} fill="none" viewBox="0 0 22 28">
    <path
      d="M11 .875A10.325 10.325 0 0 0 .688 11.188a10.208 10.208 0 0 0 2.077 6.187s.281.37.327.424L11 27.125l7.912-9.33c.04-.05.323-.42.323-.42v-.003a10.205 10.205 0 0 0 2.078-6.184A10.324 10.324 0 0 0 11 .874Zm0 14.063a3.75 3.75 0 1 1 0-7.5 3.75 3.75 0 0 1 0 7.5Z"
      fill={color}
    />
  </svg>
);

export default Marker;
