import { Box, Button } from '@mui/material';
import { useCreateMutation } from 'api';
import { NumberTextField } from 'components/NumberTextField';
import PageContainer from 'components/PageContainer';
import Select from 'components/Select';
import { useTranslation } from 'locales/i18n';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  FaksValidationExpectedResult,
  FaksValidationExpectedResultValues,
} from 'types/api/admin/trades_instances';

export const AdminFaksValidationsGenerator = () => {
  const { t } = useTranslation();
  const [tradesInstanceId, setTradesInstanceId] = useState('');
  const [expectedResult, setExpectedResult] = useState(
    'send_to_validation' as FaksValidationExpectedResult
  );

  const createFakeHighcoDataValidationRequestMutation = useCreateMutation(
    'admin/fake_faks_validations'
  );

  const createFakeHighcoDataValidationResult = async () => {
    return createFakeHighcoDataValidationRequestMutation
      .mutateAsync({
        trades_instance_id: +tradesInstanceId,
        expected_result: expectedResult,
      })
      .then((response) => {
        if (response.error_message) {
          toast.error(t(`faksValidationGenerator.errorMessages.${response.error_message}`));
        } else if (response.success) {
          toast.success(t('shared.changesDone'));
        }
      });
  };

  const expectedResultOptions = FaksValidationExpectedResultValues.map((expected_result_value) => ({
    value: expected_result_value,
    title: t(`faksValidationGenerator.expectedResultOptions.${expected_result_value}`),
  }));

  return (
    <PageContainer pageTitle={t('shared.faksValidationsGenerator')} showGoBackLink={false}>
      <Box
        display="flex"
        sx={{ mb: 2, flexDirection: 'column', gap: '20px', alignItems: 'center' }}
      >
        <NumberTextField
          variant="outlined"
          sx={{ width: '100%' }}
          placeholder={t('faksValidationGenerator.instanceId')}
          value={tradesInstanceId}
          onChange={(event) => setTradesInstanceId(event.target.value)}
          required
        />
        <Select
          label={t('faksValidationGenerator.expectedResult')}
          value={expectedResult}
          onChange={(event) =>
            setExpectedResult(event.target.value as FaksValidationExpectedResult)
          }
          options={expectedResultOptions}
          required
        />
        <Button
          onClick={() => createFakeHighcoDataValidationResult()}
          variant="outlined"
          sx={{ ml: 2, width: 'fit-content' }}
          disabled={isEmpty(tradesInstanceId) || isEmpty(expectedResult)}
        >
          {t('faksValidationGenerator.update')}
        </Button>
      </Box>
    </PageContainer>
  );
};
