import { Box } from '@mui/material';
import { FC } from 'react';
import { Spinner } from './Spinner';

export const CenteredSpinner: FC = () => (
  <Box
    alignItems="center"
    justifyContent="center"
    sx={{ display: 'flex', padding: '50px', width: '100%' }}
  >
    <Spinner size="30px" />
  </Box>
);
