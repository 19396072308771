import { includes, isEmpty } from 'lodash';

export const addOrRemoveField = (data: number[], value: number): number[] => {
  if (includes(data, value)) {
    return data.filter((item) => item === value);
  } else {
    return [...data, value];
  }
};

export const readOnly = <T extends string>(arr: readonly T[]) => arr;

export const splitAt = <T>(arr: T[], index: number) => [arr.slice(0, index), arr.slice(index)];

export const undefinedIfEmpty = <T>(arr: T[]) => (isEmpty(arr) ? undefined : arr);

export const getDuplicatedValues = <T>(arr: T[]): T[] =>
  arr.filter((value, index, array) => array.indexOf(value) !== index);
