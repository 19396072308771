import { useGetUserQuery } from 'api';
import { useSelector } from 'react-redux';
import { selectCurrentUserId, selectTrueUserId, selectUserToken } from 'redux/slices/auth';

export const useCurrentUser = () => {
  const authToken = useSelector(selectUserToken);

  const { data } = useGetUserQuery({
    enabled: !!authToken,
  });

  return data ? data.user : null;
};

export const useIsCurrentUserAdmin = () => useCurrentUser()?.kind === 'admin';
export const useIsUserOnboarder = () => useCurrentUser()?.kind === 'onboarding';

export const useIsAdminImpersonating = () => {
  const trueUserId = useSelector(selectTrueUserId);
  const currentUserId = useSelector(selectCurrentUserId);
  return !!(currentUserId && trueUserId && trueUserId !== currentUserId);
};

export const useIsTrueUserAdmin = () => {
  const isCurrentUserAdmin = useIsCurrentUserAdmin();
  const isAdminImpersonating = useIsAdminImpersonating();
  return isCurrentUserAdmin || isAdminImpersonating;
};
