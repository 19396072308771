import { includes, isNil } from 'lodash';
import { matchRoutes } from 'react-router';
import { allPaths } from 'routes/paths';
import { ResourceType, RoleWithResource } from '../types/models';

export const trackEvent = (name: TrackedActions, properties = {}): void => {
  if (!window.analytics.user || !window.analytics.user().id()) return;

  window.analytics.track(name, { ...properties, device: 'desktop' });
};

export const trackPage = (locationPathname: string, properties?: Record<string, unknown>): void => {
  const pageToTrack = getPageToTrack(locationPathname);

  if (!pageToTrack && !includes(notTrackedPage, locationPathname)) {
    // eslint-disable-next-line no-console
    console.error(`Missing tracking on page : ${locationPathname}`);
    return;
  }

  if (
    !window.analytics.user ||
    !window.analytics.user().id() ||
    !pageToTrack ||
    includes(notTrackedPage, locationPathname)
  )
    return;

  window.analytics.page(pageToTrack.name, {
    ...pageToTrack.params,
    ...properties,
    device: 'desktop',
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const trackGroup = (role: RoleWithResource, properties: Record<string, any>): void => {
  window.analytics.group(roleToCustomerIoIdentifier(role), properties);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const trackIdentify = (id: number, properties: Record<string, any>): void => {
  window.analytics.identify(id, properties);
};

export const roleToCustomerIoIdentifier = (role: RoleWithResource): string =>
  `${role.resource_type}_${role.resource_id}`;

export const getOrganizationRoleResourceTypeKey = (resourceType: ResourceType) =>
  `${resourceType.toLocaleLowerCase()}OrgId`;

// TODO : Same tracking as mobile app (except for the mobile specifics ones). Not everything is used for the moment.
export enum TrackedActions {
  // Messages
  createMessage = 'Create discussion message',
  // Claims
  createInvoice = 'Create claim',
  closeInvoice = 'Close invoice',
  // Click actions
  clickDocument = 'Click document',
  clickPhone = 'Click phone icon',
  clickPhoneTopic = 'Click phone topic',
  // Sms
  smsSent = 'Sms sent',
  smsCancelled = 'Sms cancelled',
  smsFailed = 'Sms failed',
  // Trades
  tradeSubscription = 'Subscribe to trade',
  updateTradeSubscription = 'Update subscribed trade',
  createTradeRequirementInstance = 'Create trade requirement instance',
  updateTradeRequirementInstance = 'Update trade requirement instance',
  createPharmacyTrade = 'Create pharmacy trade',
  acceptTradesInstance = 'Accept trades instance',
  refuseTradesInstance = 'Refuse trades instance',
  exportTradesInstances = 'Export trades instances',
  refusedTrade = 'Refused trade',
  cancelTradeRefusal = 'Cancel trade refusal',
  pharmacistAddCommentToTradesInstance = 'Commented report',
  // Posts
  createPost = 'Create post',
  updatePost = 'Update post',
  deletePost = 'Delete post',
  // Campaign
  createCampaign = 'Create campaign',
  postCampaignResponse = 'Respond to campaign',
  // Sales rep
  addPharmacyToSalesRep = 'Add pharmacy to sales rep',
  removePharmacyFromSalesRep = 'Remove pharmacy from sales rep',
  // Onboarding
  createProfile = 'Create profile',
  joinPharmacy = 'Join pharmacy',
  createPharmacy = 'Create pharmacy',
  // Pharmacy
  updatePharmacistRole = 'Update pharmacist role',
  addUserOnPharmacy = 'Add user on pharmacy',
  // User
  editProfile = 'Edit profile',
  // Orders
  showOrder = 'Show order',
  createOrder = 'Create order',
  // Boost
  navigateToBoost = 'Navigate to boost',
  showBoostConsentModal = 'Show boost consent modal',
  // Other
  addLabToPartners = 'Add lab to partners',
  callLab = 'Call lab support',
  openPharmacyTradeModal = 'Open pharmacy trade modal',
  uploadFile = 'Upload file',
  intercomFAQ = 'Intercom FAQ',
}

export const getPageToTrack = (currentPathname: string) => {
  const routesWithPath = Object.values(allPaths).map((path) => ({ path }));
  const routes = matchRoutes(routesWithPath, currentPathname);
  let toTrack = undefined;

  if (!isNil(routes) && routes[0].route.path) {
    const matchedPath = Object.entries(allPaths).find(
      ([_, value]) => value === routes[0].route.path
    );

    if (!matchedPath) return undefined;

    const [name] = matchedPath;
    const params = routes[0].params;

    toTrack = { name, params };
  }

  return toTrack;
};

const notTrackedPage = [
  '/pharmacy',
  '/group',
  '/lab',
  '/user',
  '/login',
  '/',
  '/admin',
  '/onboarding',
  '/admin/pharmacies',
  '/admin/pharmacies/merge',
  '/admin/users',
  '/admin/datamatrix',
];
