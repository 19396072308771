import i18n, { InterpolationMap, TOptionsBase } from 'i18next';
// eslint-disable-next-line no-restricted-imports
import { t as tBase } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { $Dictionary } from 'i18next/typescript/helpers';
import numeral from 'numeral';
// eslint-disable-next-line no-restricted-imports
import { initReactI18next, useTranslation as useBaseTranslation } from 'react-i18next';
import { ResellerWording } from 'types/models';
import frLocales from './fr';

export type DropInitDot<T> = T extends `.${infer U}` ? U : T;

type RemovePluralSuffix<T extends string> = T extends `${infer Start}_zero`
  ? Start
  : T extends `${infer Start}_one`
  ? Start
  : T extends `${infer Start}_other`
  ? Start
  : T;

export type DeepLeafKeys<T> = T extends object
  ? {
      [K in keyof T]: T[K] extends object
        ? `${K & string}.${DeepLeafKeys<T[K]>}`
        : RemovePluralSuffix<`${K & string}`>;
    }[keyof T]
  : never;

const lng = localStorage.getItem('i18nextLng') || 'fr';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      fr: { translations: frLocales },
    },
    lng,
    fallbackLng: 'fr',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

type PharmaKeys = DropInitDot<DeepLeafKeys<typeof frLocales.reseller_pharmacy>>;
type PosKeys = DropInitDot<DeepLeafKeys<typeof frLocales.reseller_point_of_sale>>;

const { reseller_pharmacy, reseller_point_of_sale, ...otherFrLocales } = frLocales;
export type OtherKeys = DropInitDot<DeepLeafKeys<typeof otherFrLocales>>;

export const useScopedResellerTranslation = (resellerWording: ResellerWording | undefined) => {
  const { t } = useBaseTranslation('translations', {
    keyPrefix: `reseller_${resellerWording || 'pharmacy'}`,
  });

  return {
    tScoped: (
      key: PharmaKeys & PosKeys,
      options?: TOptionsBase & $Dictionary & InterpolationMap<string>
    ) => t(key, options),
  };
};

export const useTranslation: () => {
  t: (key: OtherKeys, options?: TOptionsBase & $Dictionary & InterpolationMap<string>) => string;
} = useBaseTranslation;

export const t = (
  key: OtherKeys,
  options?: TOptionsBase & $Dictionary & InterpolationMap<string>
) => tBase(key, options);

numeral.locale(lng);

export default i18n;
