import SvgIconStyle from 'components/SvgIconStyle';

type NavIconProps = {
  name:
    | 'ic_mail'
    | 'ic_news'
    | 'ic_booking'
    | 'ic_invoice'
    | 'ic_pharma'
    | 'ic_lab'
    | 'ic_ecommerce'
    | 'ic_lock'
    | 'ic_settings'
    | 'ic_group'
    | 'ic_question'
    | 'ic_expired'
    | 'ic_user'
    | 'ic_scan'
    | 'ic_ingestions'
    | 'ic_cart'
    | 'ic_marketing'
    | 'ic_home'
    | 'ic_tools'
    | 'ic_info'
    | 'ic_kanban'
    | 'ic_export'
    | 'ic_analytics'
    | 'ic_banking'
    | 'ic_blog'
    | 'ic_calendar'
    | 'ic_chat'
    | 'ic_dashboard'
    | 'ic_euro';
};

export function NavIcon({ name }: NavIconProps) {
  return <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
}
