import { useTranslation } from 'locales/i18n';
import { FC } from 'react';
import { LoginLayout } from '../LoginLayout';
import { PharmacistButton } from '../PharmacistButton';
import Form from './Form';

const Invitation: FC = () => {
  const { t } = useTranslation();

  return (
    <LoginLayout
      title={t('invitation.askForInvitation')}
      subtitle={t('invitation.askForInvitationDescription')}
    >
      <Form />
      <PharmacistButton />
    </LoginLayout>
  );
};

export default Invitation;
