import { NumberTextField, NumberTextFieldProps } from 'components/NumberTextField';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type IProps = {
  name: string;
  convertTo?: 'number' | 'float';
};

type Props = IProps & NumberTextFieldProps;

export const RHFNumberTextField: FC<Props> = ({ name, convertTo, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <NumberTextField
          {...field}
          fullWidth
          error={!!error}
          helperText={error?.message}
          onChange={(e) => {
            let value: number | string = e.target.value;
            if (convertTo === 'float' && value) value = +value;
            if (convertTo === 'number' && value) value = parseInt(e.target.value);
            field.onChange(value);
          }}
          {...other}
        />
      )}
    />
  );
};
