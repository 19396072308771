import { includes, isEmpty, isNil } from 'lodash';
import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { LoginPaths, OnboardingPaths } from 'routes/paths';
import { Pharmacy, Role, User } from 'types/models';
import { PharmacyVerificationState, getPharmacyVerificationState } from 'utils/onboarding';

type Props = {
  component: ReactNode;
  pathname: string;
  pharmacy: Pharmacy | null;
  role: Role | null;
  user: User | null;
};

export const OnboardingStepCheck: FC<Props> = ({ component, pathname, pharmacy, role, user }) => {
  const pharmacyVerificationState = getPharmacyVerificationState(
    pharmacy?.verified || false,
    role?.kind
  );

  if (!user) return <Navigate to={LoginPaths.PhoneNumber} />;

  // Hacky solution to mark profile creation step
  const isProfileCreated = !isEmpty(user?.first_name);

  // Prevents the user from skipping onboarding steps
  if (
    !isProfileCreated &&
    !includes(
      [
        OnboardingPaths.CreateProfile,
        OnboardingPaths.PharmacistOrLab,
        OnboardingPaths.LabUnavailable,
      ],
      pathname
    )
  )
    return <Navigate to={OnboardingPaths.PharmacistOrLab} />;

  // Prevents the user from going back on the onboarding steps
  if (
    isProfileCreated &&
    isNil(role) &&
    !includes(
      [
        OnboardingPaths.SearchPharmacy,
        OnboardingPaths.CreatePharmacy,
        OnboardingPaths.JoinPharmacy,
      ],
      pathname
    )
  )
    return <Navigate to={OnboardingPaths.SearchPharmacy} />;

  if (
    pharmacyVerificationState === PharmacyVerificationState.email &&
    OnboardingPaths.VerifyEmail !== pathname
  )
    return <Navigate to={OnboardingPaths.VerifyEmail} />;

  if (
    pharmacyVerificationState === PharmacyVerificationState.phoneBack &&
    OnboardingPaths.VerifyAccount !== pathname
  )
    return <Navigate to={OnboardingPaths.VerifyAccount} />;
  return <>{component}</>;
};
