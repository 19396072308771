import { useCurrentUser } from '../useCurrentUser';

export const useCurrentUserOrThrow = () => {
  const currentUser = useCurrentUser();
  if (!currentUser) {
    throw new Error('Current user is not defined');
  }

  return currentUser;
};
