import { useCurrentRole } from './useCurrentRole';

export const useCurrentLab = () => {
  const currentRole = useCurrentRole();

  if (!currentRole || currentRole.resource_type !== 'Lab') {
    return null;
  }

  return currentRole.resource;
};
