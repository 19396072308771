import { NavIcon } from 'components/nav-section/NavIcon';
import { NavSection } from 'components/nav-section/NavSection';
import { NavListProps } from 'components/nav-section/type';
import { useTranslation } from 'locales/i18n';
import { AdminPaths } from 'routes/paths';

export function FaksAdminNavSection() {
  const { t } = useTranslation();

  const navItems: NavListProps[] = [
    {
      title: t('shared.home'),
      path: AdminPaths.Home,
      icon: <NavIcon name="ic_home" />,
      hasDivider: true,
    },
    {
      title: t('shared.invoices'),
      path: `${AdminPaths.Claims}?status=not_seen`,
      icon: <NavIcon name="ic_invoice" />,
    },
    {
      title: t('shared.expired'),
      path: `${AdminPaths.ExpiredProductsRequests}?status=ongoing`,
      icon: <NavIcon name="ic_expired" />,
    },
    {
      title: t('shared.orders'),
      path: `${AdminPaths.Orders}?status=transmitted`,
      icon: <NavIcon name="ic_cart" />,
    },
    { title: t('navbar.trades'), path: AdminPaths.Trades, icon: <NavIcon name="ic_ecommerce" /> },
    {
      title: t('navbar.labTrades'),
      path: AdminPaths.LabTrades,
      icon: <NavIcon name="ic_ecommerce" />,
    },
    {
      title: t('shared.participations'),
      path: AdminPaths.TradesInstances,
      icon: <NavIcon name="ic_ecommerce" />,
    },
    {
      title: t('navbar.newsfeedPosts'),
      path: AdminPaths.NewsfeedPosts,
      icon: <NavIcon name="ic_news" />,
    },
    {
      title: t('shared.inboundMails'),
      path: AdminPaths.InboundMails,
      icon: <NavIcon name="ic_mail" />,
      hasDivider: true,
    },
    {
      title: t('shared.pharmacies'),
      path: AdminPaths.Pharmacies,
      icon: <NavIcon name="ic_pharma" />,
    },
    { title: t('shared.groups'), path: AdminPaths.Groups, icon: <NavIcon name="ic_group" /> },
    { title: t('shared.labs'), path: AdminPaths.Labs, icon: <NavIcon name="ic_lab" /> },
    {
      title: t('shared.users'),
      path: AdminPaths.Users,
      icon: <NavIcon name="ic_user" />,
      hasDivider: true,
    },
    {
      title: t('navbar.tools'),
      path: '/admin/tools',
      icon: <NavIcon name="ic_tools" />,
      children: [
        {
          title: t('shared.impersonate'),
          path: AdminPaths.Impersonate,
        },
        {
          title: t('navbar.marketingTools'),
          path: AdminPaths.MarketingTools,
        },
        {
          title: t('shared.ingestionsHub'),
          path: AdminPaths.IngestionsHub,
        },
        {
          title: t('shared.datamatrix'),
          path: AdminPaths.Datamatrix,
        },
        ...(process.env.REACT_APP_ENV === 'production'
          ? []
          : [
              {
                title: t('shared.selloutPanelistGenerator'),
                path: AdminPaths.SelloutPanelistGenerator,
              },
              {
                title: t('shared.faksValidationsGenerator'),
                path: AdminPaths.FaksValidationsGenerator,
              },
            ]),
        {
          title: t('navbar.pharmaciesMerge'),
          path: AdminPaths.PharmaciesMerge,
        },
        {
          title: t('navbar.groupsLabs'),
          path: AdminPaths.GroupsLabs,
        },
        {
          title: t('navbar.checkNeedingPharmacies'),
          path: AdminPaths.CheckNeedingPharmacies,
        },
        {
          title: t('navbar.referentialPharmacies'),
          path: AdminPaths.ReferentialPharmacies,
        },
      ],
    },
  ];

  return <NavSection navItems={navItems} />;
}
