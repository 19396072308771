import { configureStore } from '@reduxjs/toolkit';
import { faksApiInstance } from 'api/api';
import {
  TypedUseSelectorHook,
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import { rootPersistConfig, rootReducer } from './rootReducer';
import { setCurrentRoleId, signOut } from './slices/auth';

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

faksApiInstance.client.interceptors.request.use((config) => {
  const auth = store.getState().auth;
  return {
    ...config,
    headers: {
      Authorization: auth?.userToken || null,
      ...(auth?.currentRoleId ? { 'X-CurrentRoleId': auth.currentRoleId } : {}),
      ...(auth?.trueUserId !== auth?.currentUserId
        ? { 'impersonated-user-id': auth?.currentUserId }
        : {}),
      ...config.headers,
    },
  };
});

faksApiInstance.client.interceptors.response.use(undefined, (error) => {
  if (
    error?.response?.status === 403 &&
    error?.response.data.error === 'access_denied_role_not_found'
  ) {
    store.dispatch(setCurrentRoleId(null));
    window.location.href = '/';
  }
  if (error?.response?.status === 401) {
    store.dispatch(signOut({}));
  }
  return Promise.reject(error);
});

const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

const useDispatch = () => useAppDispatch<AppDispatch>();

const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export { dispatch, persistor, store, useDispatch, useSelector };
