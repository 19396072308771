import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/system';
import { useIndexQuery } from 'api';
import { FormProvider, TextField } from 'components/hook-form';
import { useTranslation } from 'locales/i18n';
import { Dispatch, FC, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router';
import { navigateToIdentityProvider } from 'utils/navigationUtils';
import { emailValidator } from 'utils/yupValidators';
import * as Yup from 'yup';
import { Step } from './VerifyEmail';

type FormValuesProps = {
  email: string;
};

type Props = {
  setEmail: Dispatch<SetStateAction<string | undefined>>;
  setStep: Dispatch<SetStateAction<Step>>;
};

export const EmailForm: FC<Props> = ({ setEmail, setStep }) => {
  const { t } = useTranslation();
  const redirectionUrl = useOutletContext<string>();

  const formSchema = Yup.object().shape({ email: emailValidator('email') });

  const defaultValues = { email: '' };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = methods;

  const { email } = watch();

  const useGetAuthenticationMethod = useIndexQuery(
    'get_authentication_method',
    { email_address: email },
    { enabled: false }
  );

  const getAuthenticationMethod = async () => {
    setEmail(email);
    useGetAuthenticationMethod.refetch().then((response) => {
      if (response.data?.authentication_method !== 'identity_provider') {
        setStep('password');

        return;
      }

      return navigateToIdentityProvider(response.data.identity_provider_url, redirectionUrl);
    });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(getAuthenticationMethod)}>
      <Stack spacing={3}>
        <TextField
          name="email"
          label={t('login.verify_email.form.your_email')}
          placeholder={t('login.verify_email.form.enter_your_email')}
          required
        />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ mt: 3 }}
        >
          {t('shared.connect')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};
