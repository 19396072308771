import { useTheme as theme } from '@mui/material/styles';
import { FC } from 'react';
import { SvgIconProps } from 'types/icons';

const Euro: FC<SvgIconProps> = ({ color = theme().palette.grey[500], size = 30 }) => (
  <svg width={size} height={size} fill="none" viewBox="0 0 27 27">
    <path
      d="M13.5 26.416C6.366 26.416.583 20.634.583 13.5S6.366.583 13.5.583c7.134 0 12.917 5.783 12.917 12.917 0 7.134-5.783 12.916-12.917 12.916Zm-2.519-14.208a3.23 3.23 0 0 1 5.25-1.821l2.197-1.464A5.813 5.813 0 0 0 8.37 12.208H7.042v2.583h1.326a5.812 5.812 0 0 0 10.06 3.285l-2.197-1.465a3.229 3.229 0 0 1-5.25-1.82l6.394.002v-2.584h-6.394v-.001Z"
      fill={color}
    />
  </svg>
);

export default Euro;
