import { Paper, PaperProps, Typography } from '@mui/material';
import { useTranslation } from 'locales/i18n';
import { FC } from 'react';

interface Props extends PaperProps {
  searchQuery?: string;
}

export const SearchNotFound: FC<Props> = ({ searchQuery = '', ...other }) => {
  const { t } = useTranslation();

  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        {t('notFound.noResult')}
      </Typography>
      <Typography variant="body2" align="center">
        {t('notFound.noResultFor')} &nbsp;
        {searchQuery ? <strong>&quot;{searchQuery}&quot;</strong> : t('notFound.yourSearch')}.{' '}
        {t('notFound.checkTypos')}
      </Typography>
    </Paper>
  );
};

export default SearchNotFound;
