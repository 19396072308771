import { useTheme as theme } from '@mui/material/styles';
import { FC } from 'react';
import { SvgIconProps } from 'types/icons';

const Users: FC<SvgIconProps> = ({ color = theme().palette.grey[500], size = 24 }) => (
  <svg width={size} height={size} fill="none" viewBox="0 0 24 21">
    <path
      d="M7 8.45h-.725a7.225 7.225 0 0 0-5.358 2.1l-.2.233v6.9h3.4v-3.916l.458-.517.208-.242a9.167 9.167 0 0 1 3.925-2.383A5.492 5.492 0 0 1 7 8.45ZM23.117 10.525a7.225 7.225 0 0 0-5.359-2.1c-.303.001-.606.018-.908.05a5.491 5.491 0 0 1-1.667 2.042 9.092 9.092 0 0 1 4.167 2.5l.208.233.45.517v3.925h3.284v-6.934l-.175-.233ZM6.25 6.825h.258A5.375 5.375 0 0 1 9.1 1.583a3.408 3.408 0 1 0-2.85 5.275v-.033ZM17.358 6.2c.01.192.01.384 0 .575.16.025.322.04.484.042H18a3.409 3.409 0 1 0-3.108-5.15A5.441 5.441 0 0 1 17.358 6.2ZM11.892 9.933a3.725 3.725 0 1 0 0-7.45 3.725 3.725 0 0 0 0 7.45Z"
      fill={color}
    />
    <path
      d="M12.092 11.917a8.075 8.075 0 0 0-5.925 2.25l-.209.233v5.275a1.309 1.309 0 0 0 1.334 1.283h9.575a1.309 1.309 0 0 0 1.333-1.283v-5.258l-.2-.25a7.983 7.983 0 0 0-5.908-2.25Z"
      fill={color}
    />
  </svg>
);

export default Users;
