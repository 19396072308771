import { SxProps } from '@mui/material';
import { NotifBadge } from './styles';

interface Props {
  count?: number;
  sx?: SxProps;
}

export const NotifBadgeIcon = ({ count, sx }: Props) => (
  <NotifBadge sx={{ ...sx }}>{count || '1'}</NotifBadge>
);
